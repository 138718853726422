import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import DashboardCardUniversityHub from "./DashboardCardUniversityHub";
import DashboardResourcesViewer from "./DashboardResourcesViewer";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#16192B",
    width: "100%",
    padding: 24,
  },
  subtitleContainer: {
    margin: "40px 0",
  },
}));

const DashboardUniversityHub = ({ textSize, university_hub }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <DashboardTitle
        variant="dark.white"
        fontWeight={500}
        fontSize={28}
        text="University Hub"
      />
      <div className="mt-5">
        <DashboardResourcesViewer
          textSize={textSize}
          views={university_hub?.per_day_view_count}
          text="Resources Viewed"
        />
      </div>
      <div className={classes.subtitleContainer}>
        <DashboardTitle
          variant="dark.white"
          fontWeight={500}
          fontSize={20}
          text="Most Viewed Resources"
        />
      </div>
      {university_hub?.most_viewed_resources?.length &&
        university_hub.most_viewed_resources.map((item) => (
          <div key={item.title} className="mt-5 px-3">
            <DashboardCardUniversityHub
              src={item.staff_member.avatar}
              date={item.timestamp}
              title={item.title}
              views={item.view_count}
              userName={item.staff_member?.full_name}
              userDegree={item.staff_member?.staff_type}
              icon={item.file_type}
            />
            {console.log({ item })}
          </div>
        ))}
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  university_hub: store.dashboard.data.dashboard_data.university_hub,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardUniversityHub);
