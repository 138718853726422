/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Dialog, DialogContent, DialogTitle, MuiThemeProvider, Paper, Tab, Tabs, Tooltip, useMediaQuery } from '@material-ui/core'
import { Add, Delete, Visibility } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'

import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { actionTableMuiTheme } from '../table/table_styles'
import ContentsDialog from './ContentsDialog'

import { defaultContent, deleteContent, getContent, getContents } from '../../crud/api/content.crud'
import * as contentsDUCK from "../../../redux/ducks/contents.duck"
import { setLoader } from '../../../redux/ducks/load.duck'
import FilterDataTable from '../../utils/FilterDataTable'
import { setAlert } from '../../../redux/ducks/alert.duck'

const Contents = ({ contents, contentActions, settingLoader, setAlert }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [htmlContent, setHtmlContent] = useState("")
  const [tabValue, setTabValue] = useState(1)
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")
  console.log('contents', contents);
  useEffect(() => {
    if (query && query.length > 1) getContents(contentActions.setContents, 1, tabValue, query)

    else getContents(contentActions.setContents, 1, tabValue)
  }, [contentActions.setContents, query, tabValue])

  const deleteCont = id => {
    setAlert({
      title: "Delete content",
      message: "Are you sure to permanently delete this content?",
      btn_msg: "Delete content",
      action: () => deleteContent(id).then(() => getContents(contentActions.setContents, contents.page))
    })
  }

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "file_type",
      label: "File Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          let src
          let type = value.toLowerCase()

          if (type === "audio") src = `${toAbsoluteUrl("/media/assets/audio-icon.png")}`
          if (type === "text" || type === "html") src = `${toAbsoluteUrl("/media/assets/text-icon.png")}`
          if (type === "link") src = `${toAbsoluteUrl("/media/assets/link-icon.png")}`
          if (type === "video") src = `${toAbsoluteUrl("/media/assets/video-icon.png")}`
          if (type === "pdf") src = `${toAbsoluteUrl("/media/assets/pdf-icon.png")}`
          if (type === "document") src = `${toAbsoluteUrl("/media/assets/docx-icon.png")}`
          if (type === "image") src = `${toAbsoluteUrl("/media/assets/image")}`
          
          return src && (
            <img width="40px" height="40px" src={src} alt="file_type" style={{ cursor: "pointer" }}/>
          )
        }
      }
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "author",
      label: "Author",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value.author}</>
        )
      }
    },
    {
      name: 'content',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta, updater) => {
          
          return (
          <div className="d-flex">
            <Tooltip title="Edit Content" placement="top">
              <a id={`edit_content_btn_${ meta.rowData[0]}`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog("editing", meta.rowData[0])}>
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>
            <Tooltip title="View Content" placement="top">
            {meta.rowData[2] ? meta.rowData[2] === "html" ?
              <a id={`view_content_btn_${ meta.rowData[0]}`} name="content" className="btn btn-light btn-hover-success text-success btn-sm mx-3" onClick={() => setHtmlContent(value)}>
                <Visibility fontSize="small" />
              </a>
             : 
              <a id={`view_content_btn_${ meta.rowData[0]}`} href={value} target="_blamk" className="btn btn-light btn-hover-success text-success btn-sm mx-3" name="content">
                <Visibility fontSize="small" />
              </a>
              :
              <button id={`view_content_btn_${ meta.rowData[0]}`} className="btn btn-light btn-hover-success text-success btn-sm mx-3" disabled>
                <Visibility fontSize="small" />
              </button>
            }
            </Tooltip>
            <Tooltip title="Delete ConsetContentstent" placement="top">
              <a id={`delete_content_btn_${ meta.rowData[0]}`} className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger" onClick={() => deleteCont(meta.rowData[0])}>
                <Delete />
              </a>
            </Tooltip>
          </div>
        )}
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: contents.rowsPerPage,
    page: contents.page - 1,
    pages: contents.pages,
    count: contents.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "contents.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: (mPage) => {
      settingLoader(true)
      getContents(contentActions.setContents, mPage +1, tabValue, query)
    }
  };

  const openDialog = async (mode, id = null) => {
    switch(mode){
      case "create":
        setDialog(mode)
        contentActions.setContent(defaultContent)
        break;
      case "editing":
        settingLoader(true)
        try {
          await getContent(contentActions.setContent, id)
          setDialog(mode)
          
        } catch (error) {}
        break;
      case "close":
        setDialog(mode)
        setTimeout(() => {
          contentActions.setContent(defaultContent)
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode")
    }        
  }

  const HtmlDialogContent = () => (
    <Dialog id="view_content_dialog" open={Boolean(htmlContent)} onClose={() => setHtmlContent("")} fullWidth maxWidth="md">
      <DialogTitle>Content</DialogTitle>
      <DialogContent>
        <span dangerouslySetInnerHTML={{__html: htmlContent}} />
      </DialogContent>
    </Dialog>
  )

  return (
    <>
      <Paper className="mb-3">
        <Tabs
          value={tabValue}
          onChange={(e, value) => {settingLoader(true); setTabValue(value)}}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered
        >
          <Tab id="tab_content_mind_selector" value={1} label="Mind"/>
          <Tab id="tab_content_body_selector" value={2} label="Body"/>
          <Tab id="tab_content_life_selector" value={3} label="Life"/>
        </Tabs>
      </Paper>
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_content_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New Content</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title="Contents"
            columns={columns}
            data={contents.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <ContentsDialog mode={dialog} setDialog={openDialog} page={contents.page} />
      <HtmlDialogContent />
    </>
  )
}

const mapStateToProps = store => {
  return ({
    contents: store.contents_.contents,
  })}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  contentActions: bindActionCreators(contentsDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
