import React, { useEffect, useState } from 'react'
import { Grid, TextField, MenuItem, Divider, makeStyles, FormControl, InputLabel, Select } from '@material-ui/core'
import { connect } from 'react-redux'

import { DropNCrop } from '../../../utils/DropNCrop'
import MaskedPhoneInput from '../../../utils/MaskedPhoneInput'
import { SCHOOL_URL } from '../../../crud/helpers/routes'
import { getMultipartParams, handleResponse } from '../../../crud/helpers/validate'

const useStyles = makeStyles((theme) => ({
  root: {
    '& input:valid + fieldset': {
      borderColor: '#17c191',
      color: '#17c191'
    },
    '& input:valid:focus + fieldset': {
      padding: '4px !important', // override inline-style
    },
  },
}));

const StudentTabInfo = ({ values, errors, touched, setFieldValue, handleChange, handleBlur, school, staff_member_admin, is_admin }) => {
  const [teams, setTeams] = useState([])
  const [errorCode, setErrorCode] = useState("")
  const [successCode, setSuccessCode] = useState("")
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const [code, setCode] = React.useState(Math.floor(Math.random() * 999999) + 1);

  const getTeams = (setterFunction, slug) => {
    return fetch(`${SCHOOL_URL}/${slug}/teams?per_page=100`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json.items))
    .catch(e => {
      console.error(e.errors)
      return e
    })
  }

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) getTeams(setTeams, school.slug)
  }, [school])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!(staff_member_admin || is_admin)}
            fullWidth
            variant="outlined"
            id="alumni_first_name_input_dialog"
            required
            label="First Name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.first_name && errors.first_name}
            error={Boolean(touched.first_name && errors.first_name)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!(staff_member_admin || is_admin)}
            fullWidth
            variant="outlined"
            id="alumni_last_name_input_dialog"
            required
            label="Last Name"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.last_name && errors.last_name}
            error={Boolean(touched.last_name && errors.last_name)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!(staff_member_admin || is_admin)}
            fullWidth
            variant="outlined"
            id="alumni__input_dialog"
            required
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedPhoneInput disabled={!(staff_member_admin || is_admin)} value={values.phone_number} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={12}>
          <h5 style={{ position: "absolute", marginTop: -25 }}>Student Picture</h5>
        </Grid>
        <Grid item xs={12}>
          <DropNCrop disabled={!(staff_member_admin || is_admin)}  setterFunction={setFieldValue} file={values.image}/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled
          fullWidth
          label="School"
          variant="outlined"
          id="alumni_school_input_dialog"
          defaultValue={school && school !== null && school.name && school.name}
        />
      </Grid>
          <TextField
            disabled={!(staff_member_admin || is_admin)}
            style={{display:'none'}}
            fullWidth
            variant="outlined"
            id="alumni_code_input_dialog"
            label="Code"
            name="code"
            defaultValue={values.code = code}
          />
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel shrink>Team</InputLabel>
          <Select
            disabled={!(staff_member_admin || is_admin)}
            displayEmpty
            inputProps={{ id: "alumni_team_input_dialog" }}
            label="Team"
            name="team_id"
            value={values.team_id}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <MenuItem value="" id="team_null">No team</MenuItem>
            {teams.map((team, i) => (
              <MenuItem key={team.id} value={team.id} id={`team_${team.i}`}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled={!(staff_member_admin || is_admin)}
          select
          fullWidth
          variant="outlined"
          id="alumni_classification_input_dialog"
          label="Classification"
          name="classification"
          value={values.classification}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.classification && errors.classification}
          error={Boolean(touched.classification && errors.classification)}
        >
          <MenuItem id="classification_freshman" value="Freshman">Freshman</MenuItem>
          <MenuItem id="classification_Junior" value="Junior">Junior</MenuItem>
          <MenuItem id="classification_Sophomore" value="Sophomore">Sophomore</MenuItem>
          <MenuItem id="classification_Senior" value="Senior">Senior</MenuItem>
        </TextField>
      </Grid>
      {/* <Grid item xs={12}>
        <Divider style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          variant="outlined"
          label="Height"
          name="height"
          value={values.height}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          variant="outlined"
          label="Weight"
          name="weight"
          value={values.weight}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          select
          variant="outlined"
          label="gender"
          name="gender"
          value={values.gender}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
        </TextField>
      </Grid> */}
      {/* <Grid item xs={12}>
        <DropNCrop setterFunction={setFieldValue} file={values.image} />
      </Grid> */}
    </Grid>
  )
}

const mapStateToProps = store => {
  return ({
    school: store.school.selected_school,
    staff_member_admin: store.auth.user?.staff_member_admin,
    is_admin: store.auth.user?.is_admin,
  })
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StudentTabInfo)
