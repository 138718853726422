import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: ({ fontSize }) => fontSize,
    color: "#9ACEFF",
    fontWeight: ({ fontWeight }) => fontWeight,
    lineHeight: ({ lineHeight }) => `${lineHeight}px`,
    whiteSpace: ({ whiteSpace }) => whiteSpace,
  },
}));

const DashboardTitle = ({
  text = "",
  fontSize = 16,
  fontWeight = 400,
  lineHeight,
  variant = "primary",
  whiteSpace,
}) => {
  const classes = useStyles({
    fontSize,
    fontWeight,
    variant,
    lineHeight,
    whiteSpace,
  });
  return <Typography className={classes.title}>{text}</Typography>;
};

export default DashboardTitle;
