import React from 'react'
import { IconButton, Paper, TextField } from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'

const FilterDataTable = ({ query, setQuery }) => {

  return (
    <Paper style={{ width: 400 }}>
      <TextField
        fullWidth
        id="autocomplete_filter_input"
        size="small"
        variant="outlined"
        name="query"
        value={query}
        placeholder="Search..."
        autoFocus
        onChange={e => setQuery(e.target.value)}
        inputProps={{ autoComplete: "off" }}
        InputProps={{
          endAdornment: (
            <>
              {query && <IconButton id="clear_automplete_input_btn" size="small" onClick={() => setQuery("")}>
                <Clear />
              </IconButton>}
              <Search />
            </>
          )
        }}
      />
    </Paper>
  )
}

export default FilterDataTable