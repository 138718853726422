import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultStaffMember } from '../../app/crud/api/staff_members.crud';

export const actionType = {
  GetStaffMembers: '[STAFF_MEMBER] GET_STAFF_MEMBERS',
  GetStaffMember: '[STAFF_MEMBER] GET_STAFF_MEMBER',
  UpdateStaffMember: '[STAFF_MEMBER] UPDATE_STAFF_MEMBER',
  DeleteStaffMember: '[STAFF_MEMBER] DELETE_STAFF_MEMBER',
  LoadStaffMemberCsv: '[STAFF_MEMBER] LOAD_STAFF_MEMBER_CSV',
  SelectStaffMember: '[STAFF_MEMBER] SELECT_STAFF_MEMBER'
}

const initialState = {
  staffMembers: { data: [], isFetched: false },
  staffMember: defaultStaffMember,
  selectedStaffMember: null,
}

export const reducer = persistReducer(
  { storage, key: 'staffM', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetStaffMembers: {
        return { ...state, staffMembers: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateStaffMember: {
        let newData = state.staffMembers.data
        const index = state.staffMembers.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.staffMembers.data]
        } else {
          newData = state.staffMembers.data
          newData[index] = action.payload
        }

        return { ...state, staffMembers: { ...state.staffMembers, data: newData } }
      }

      case actionType.DeleteStaffMember: {
        let newData = state.staffMembers.data
        const index = state.staffMembers.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.staffMembers.data]
        } else {
          newData = state.staffMembers.data
          newData.splice(index, 1)
        }

        return { ...state, staffMembers: { ...state.staffMembers, data: newData } }
      }
      
      case actionType.GetStaffMember: {
        
        return { ...state, staffMember: action.payload }
      }

      case actionType.LoadStaffMemberCsv: {
        let newData =  action.payload
        
        return { ...state, staffMembers: { ...state.staffMembers, data: [...state.staffMembers.data, ...newData] } }
      }

      case actionType.SelectStaffMember: {

        return { ...state, selectedStaffMember: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setStaffMembers: data => ({ type: actionType.GetStaffMembers, payload: data }),
  setStaffMember: data => ({ type: actionType.GetStaffMember, payload: data }),
  updateStaffMembers: data => ({ type: actionType.UpdateStaffMember, payload: data }),
  deleteStaffMembers: data => ({ type: actionType.DeleteStaffMember, payload: data }),
  loadStaffMemberCsv: data => ({ type: actionType.LoadStaffMemberCsv, payload: data }),
  setSelectedStaffMember: state => ({ type: actionType.SelectStaffMember, payload: state })
}