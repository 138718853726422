import notice from "../../utils/notice";
import { DASHBOARD_URL } from "../helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleResponse,
} from "../helpers/validate";

export const defaultDailyPick = {
  content: null,
  display_order: "",
};

export const getDashboardData = (
  date_from,
  date_to,
  team,
  month,
  mood,
  slug
) => {
  return fetch(
    `${DASHBOARD_URL}?${date_from ? `date_from=${date_from}&` : ""}${
      date_to ? `date_to=${date_to}&` : ""
    }${month ? `month=${month}&` : ""}${team ? `team=${team}&` : ""}${
      mood ? `mood=${mood}&` : ""
    }${slug ? `slug=${slug}` : ""}`,
    getMultipartParams("GET")
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      console.log("..........................");
      console.log(json);
      return json;
    })
    .catch((e) => {
      notice("Error fetching daily picks");
      console.error(e.errors);
    });
};
