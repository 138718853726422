/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete, Visibility } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

import { actionTableMuiTheme } from "../table/table_styles";
import FilterDataTable from "../../utils/FilterDataTable";
import PollsDialog from "./PollsDialog";

import * as pollDUCK from "../../../redux/ducks/poll.duck";
import { setLoader } from "../../../redux/ducks/load.duck";
import { getPolls, defaultPoll, getPoll } from "../../crud/api/polls.crud";

const Polls = ({
  polls,
  pollActions,
  settingLoader,
  school,
  staff_member_admin,
  is_admin,
}) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [dialog, setDialog] = useState("close");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1) getPolls(pollActions.setPolls, 1, query);
      else getPolls(pollActions.setPolls, 1);
    }
  }, [query, school, pollActions.setPolls]);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "question_text",
      label: "Question",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "teams_count",
      label: "Teams",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "options_count",
      label: "Options",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "answers_count",
      label: "Answers",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Edit Poll" placement="top">
              <a
                id={`edit_poll_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                {staff_member_admin || is_admin ? (
                  <i className="fas fa-edit text-primary" />
                ) : (
                  <Visibility />
                )}
              </a>
            </Tooltip>
            {(staff_member_admin || is_admin) && (
              <Tooltip title="Delete Poll" placement="top">
                <a
                  id={`delete_poll_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                >
                  <Delete />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: polls.rowsPerPage,
    page: polls.page,
    count: polls.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "polls.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    onChangePage: (mPage) => {
      getPolls(pollActions.setPolls, mPage + 1, query);
      settingLoader(true);
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        pollActions.setPoll(defaultPoll);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getPoll(pollActions.setPoll, id).then(() => setDialog(mode));
        break;
      case "close":
        settingLoader(true);
        setDialog(mode);
        setTimeout(() => {
          pollActions.setPoll(defaultPoll);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };
  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        {/* <FilterDataTable query={query} setQuery={setQuery} /> */}
        {(staff_member_admin || is_admin) && (
          <Button
            className="ml-auto mr-0"
            id="create_poll_btn"
            variant="contained"
            color="secondary"
            onClick={() => openDialog("create")}
          >
            <Add /> New poll
          </Button>
        )}
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title="Polls"
            columns={columns}
            data={polls.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <PollsDialog mode={dialog} setDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  polls: store.poll.polls,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
});

const mapDispatchToProps = (dispatch) => ({
  pollActions: bindActionCreators(pollDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
