/* eslint-disable no-throw-literal */
import notice from "../../utils/notice"
import { CONTENTS_URL, LIBRARY_URL, CREATE_CONTENTS_URL, CONTENTS_URL_v2} from "../helpers/routes"
import { getMultipartParams, handleResponse } from "../helpers/validate"

export const defaultContent = {
  name: "",
  description: "",
  content: null,
  content_type: "",
  author_name: "",
  focus_areas_ids: "",
  categories_id: "",
  moods_id: "",
  contributing_factors_id: "",
  image: null,
  logo: null,
  duration: "",
  duration_minutes: "",
  content_partner: ""
}

export const getContents = (setterFunction, page = 1, zone_id = 1, query = "") =>  {
  return fetch(`${LIBRARY_URL}/list-category?category_id=${zone_id}&page=${page}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading content ")
    console.error(e.errors)
  })
}

export const getContentsList = (setterFunction, page = 1, zone_id = 1) =>  {
  return fetch(CONTENTS_URL, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json.items))
  .catch(e => {
    notice("Error on loading content ")
    console.error(e.errors)
  })
}

export const getContent = (setterFunction, id) =>  {
  return fetch(`${CONTENTS_URL_v2}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading content ")
    console.error(e.errors)
  })
}

export const saveContent = (setterFunction, data) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }
  
  return fetch(CREATE_CONTENTS_URL, getMultipartParams('POST', formData))
  .then(res => handleResponse(res))
  .then(json => {
    //TODO replace with api response
    /*if (json && json.id) {
      notice("Content created", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the content")
      throw {error: json}
    }*/
    notice("Content created", "success")
  })
  .catch(e => {
    notice("Error saving the content")
    console.error(e.errors)
    throw e
  })
}

export const updateContent = (setterFunction, data, id) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }
  
  return fetch(`${CONTENTS_URL_v2}/update?id=${id}`, getMultipartParams('PUT', formData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json && json.id) {
      notice("Content  updated", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the content")
      throw {error: json}
    }
  })
  .catch(e => {
    notice("Error saving the content")
    console.error(e.errors)
    throw e
  })
}

export const deleteContent = (id) =>  {
  return fetch(`${CONTENTS_URL}/delete?id=${id}`, getMultipartParams("DELETE"))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Content deleted", "success")
    return json
  })
  .catch(e => {
    notice("Error deleting the content")
    console.error(e.errors)
    return e
  })
}