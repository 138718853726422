import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashboardChart from "./components/DashboardChart";
import {
  getStudentMoods,
  getTopMoodDay,
  getTopMoodWeek,
} from "../../crud/api/mood.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import DashboardTitle from "./components/DashboardTitle";
import TeamsList from "./components/TeamsList";
import DropdownMonths from "./components/DropdownMonths";
import RotatedBackground from "./components/RotatedBackground";
import DashboardCardList from "./components/DashboardCardList";
import TeleHelpNotifications from "./components/TelehelpNotifications";
import TeleHelpNotificationsSentCount from "./components/TeleHelpNotificationsSentCount";
import DashboardLibraryList from "./components/DashboardLibraryList";
import DashboardUniversityHub from "./components/DashboardUniversityHub";
import DashboardTextSlider from "./components/DashboardTextSlider";
import { useBreakpoints } from "../../hooks/useBreakpoint";
import DashboardFocusAreasListTop3 from "./components/DashboardFocusAreasListTop3";
import { useTraduction } from "../../hooks/useTraduction";
import DashboardDownloadDropdown from "./components/DashboardDownloadDropdown";
import DashboardFocusAreasList from "./components/DashboardFocusAreasList";
import DashboardFiltersDropdown from "./components/DashboardFiltersDropdown";
import { MenuItem } from "@material-ui/core";
import DashboardChipInfoUsage from "./components/DashboardChipInfoUsage";
import DashboardChipInfoLaunches from "./components/DashboardChipInfoLaunches";
import DashboardChipActiveStudents from "./components/DashboardChipActiveStudents";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#040719",
    minHeight: "100%",
    padding: "30px 50px",
  },
  paginator: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#040719",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  headerContainer: {
    minHeight: 80,
  },
  rightHeader: {
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  teamsRow: {
    marginBottom: 100,
  },
  chipsContainer: {
    justifyContent: "space-between",
  },
  topLists: {
    marginTop: 15,
  },
  factorslist: {
    marginTop: -130,
    width: "100%",
  },
  menuItem: {
    padding: "4px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const DashboardS = ({ school, is_admin }) => {
  const classes = useStyles();
  const [data, setData] = useState({ labels: [], series: [] });
  const [topDay, setTopDay] = useState(null);
  const [topWeek, setTopWeek] = useState(null);

  const { t } = useTraduction();

  useBreakpoints();

  useEffect(() => {
    if (school && school !== null && school.slug) {
      getStudentMoods(school.slug, setData);
      getTopMoodDay(school.slug, setTopDay);
      getTopMoodWeek(school.slug, setTopWeek);
    }
  }, [school]);

  // Si todas las pantallas serán tema dark, conviene mover este container a un punto por encima de las rutas
  return (
    <div className={classes.container}>
      <Grid container className="p-8" spacing={2}>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Grid container item xs={10}>
            <DashboardTitle
              fontWeight={500}
              fontSize={24}
              text={t("DASHBOARD.DASHBOARD")}
            />
          </Grid>
          <Grid container item xs={2}>
            <DashboardDownloadDropdown />
          </Grid>
        </Grid>
        <Grid className={classes.teamsRow} container item xs={12}>
          <Grid className="mb-5" container item xs={12}>
            <Grid className="mb-5" container item xs={12}>
              <DashboardTitle
                lineHeight={1}
                variant="dark.white"
                fontWeight={300}
                fontSize={24}
                text={t("DASHBOARD.CURRENTLY_VIEWING")}
              />
            </Grid>
            <Grid className="mt-2" container item xs={12}>
              <TeamsList />
            </Grid>
          </Grid>
          <Grid className={classes.rightHeader} container xs={12}>
            <Grid xs={4}></Grid>
            <Grid className="justify-content-center" container item xs={4}>
              <DashboardFiltersDropdown />
            </Grid>
            <Grid item xs={4}></Grid>
            <div className="d-flex justify-content-center m-auto mt-5">
              <Grid container item xs={6}>
                <Grid container item xs={2}></Grid>
                <Grid container item xs={10}>
                  <DashboardTitle
                    variant="grey2"
                    fontWeight={400}
                    fontSize={15}
                    text={t("DASHBOARD.MONTH")}
                  />
                  <Grid container item xs={12}>
                    <DropdownMonths />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid container item xs={12}>
                  <DashboardTitle
                    variant="grey2"
                    fontWeight={400}
                    fontSize={15}
                    text={t("DASHBOARD.WEEK")}
                  />
                  <Grid container item xs={12}>
                    <DashboardTextSlider />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <DashboardTitle
            variant="dark.white"
            fontWeight={500}
            fontSize={28}
            text={t("DASHBOARD.GENERAL_MOOD")}
          />
          <div style={{ zIndex: 2 }}>
            <DashboardChart labels={data.labels} series={data.series} />
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              bottom: 130,
              zIndex: 1,
              width: "100%",
            }}
          >
            <RotatedBackground />
          </div>
          <span style={{ marginTop: -130 }}></span>
          <div className={classes.factorslist}>
            <DashboardCardList percent={100} />
          </div>
          <div className="m-5"></div>
          <Grid container item xs={12} className={classes.chipsContainer}>
            <DashboardChipActiveStudents width="45%" />
            {/* Add margin to third item if usage is uncommented */}
            {/* <DashboardChipInfoUsage width='45%'/> */}
            <DashboardChipInfoLaunches width="45%" />
          </Grid>
          <div className="m-5"></div>
          <TeleHelpNotifications text={t("DASHBOARD.TELEHELP_DRAFTS_SENT")} />
          {is_admin && (
            <TeleHelpNotificationsSentCount
              text={t("DASHBOARD.TELEHELP_SENT_COUNT")}
            />
          )}
          <Grid item xs={12}>
            <DashboardTitle
              variant="dark.white"
              fontWeight={500}
              fontSize={28}
              text={t("DASHBOARD.FOCUS_AREAS")}
            />
            <DashboardTitle
              variant="dark.white"
              fontWeight={500}
              fontSize={17}
              text={t("DASHBOARD.TOP_3")}
            />
            <Grid
              className={classes.topLists}
              container
              item
              xs={12}
              spacing={2}
            >
              <MenuItem className={classes.menuItem}>
                <DashboardFocusAreasListTop3 />
              </MenuItem>
              <MenuItem className={classes.menuItem}>
                <DashboardFocusAreasList />
              </MenuItem>
            </Grid>
          </Grid>
          <DashboardLibraryList textSize={17} />
          <DashboardUniversityHub textSize={17} />
        </Grid>
        <Grid container item xs={6}></Grid>
        <Grid container item xs={6} spacing={3}>
          <Grid container item xs={12}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (store) => ({
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
});

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardS);
