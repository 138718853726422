import { USERS_URL } from "../helpers/routes"
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import notice from "../../utils/notice"

export const resetPassword = (data) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${USERS_URL}/request_password_reset`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => notice("Password reset triggered", "success"))
  .catch(e => {
    notice("Error sending reset instructions")
    console.error(e.errors)
    throw e
  })
}

export const checkAuthorize = () => {
  return fetch(`${USERS_URL}/me`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => json)
  .catch(e => e)
}