import { SCHOOL_URL, STUDENTS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams, handleFormData } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultStudent = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  code: "",
  team_id: "",
  classification: "",

  height: "",
  weight: "",
  gender: "",

  status: true,
  nick_name: "",
}

export const getStudents = (setterFunction, slug, page = 1, query = "") => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/students?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading students")
      console.error(e.errors)
    })
  }
}

export const getStudent = (setterFunction, id) => {
  return fetch(`${STUDENTS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on fetching student")
    console.error(e.errors)
    throw e
  })
}

export const saveStudent = (setterFunction, data) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(`${STUDENTS_URL}`, getMultipartParams('POST', formData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Student created", "success")
    return json.id && setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the student")
    console.log(e.errors)
    throw e
  })
}

export const updateStudent = (setterFunction, code, data) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(`${STUDENTS_URL}/${code}`, getMultipartParams('PUT', formData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json && json.id) {
    notice("Student updated", "success")
    return setterFunction(json)
    } else notice("Error saving the student")
  })
  .catch(e => {
    notice("Error saving the student")
    console.log(e.errors)
    throw e
  })
}

export const deleteStudent = (setterFunction, id) => {
  return fetch(`${STUDENTS_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Student deleted", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error deleting the student")
    console.log(e.errors)
    throw e
  })
}

export const searchStudents = (setterFunction, slug, query) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/students/suggestions?q=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading staff members")
      console.error(e.errors)
    })
  }
}

export const genStudentCode = (setterFunction, slug, data) => {
  const jsonData = JSON.stringify(data)
  
  return fetch(`${SCHOOL_URL}/${slug}/students/request_access_code`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    setterFunction("code", json.code)
  })
  .catch(e => {
    notice("Error fetching code")
    console.log(e.errors)
  })
}

export const validateStudentCode = (slug, value, setterError, setterSuccess, setterFunction) => {
  const jsonData = JSON.stringify(value)

  return fetch(`${SCHOOL_URL}/${slug}/students/validate_student_code`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json.valid_code) {
      setterSuccess("Valid Code")
    } else {
      setterSuccess("")
      setterError(`Code ${value.code} is not available`)
      setTimeout(() => {
        setterFunction("code", "")
        setterError("")
      }, 2000);
    }
  })
  .catch(e => {
    console.error(e.errors)
    setterError(`Code ${value.code} is not available`)
    setterSuccess("")
    setTimeout(() => {
      setterFunction("code", "")
      setterError("")
    }, 2000);
  })
}

export const notifyStudent = (email) => {
  return fetch(`${STUDENTS_URL}/notify?email=${email}`,getParams('GET'))
  .then(res => handleResponse(res))
  .then(() => notice("Student code sent","success"))
  .catch(e => {
    notice("Error resending student code")
    console.log(e.errors)
    throw e
  })
}

export const studentBulk = (slug, file, team_id) => {
  return fetch(`${SCHOOL_URL}/${slug}/students/bulk_creation`, getMultipartParams('POST', handleFormData({file: file, team_id })))
  .then(handleResponse)
  .then(res => notice(res.message, "success"))
  .catch(e => {
    notice("Error creating bulk")
    console.log(e.errors)
    throw e
  })
}