import { MenuItem, useTheme } from "@material-ui/core";
import { makeStyles, Grid, darken } from "@material-ui/core";
import React from "react";
import { useIcons } from "../../../hooks/useIcons";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: ({ isSelected }) =>
      isSelected ? darken("#00B9E1", 0.85) : "#23263F",
    padding: "0px 16px",
    borderRadius: 8,
    border: ({ isSelected }) => isSelected && `1px solid ${"#00B9E1"}`,
    // Applies to all texts
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    maxHeight: 40,
  },
  percent: {
    display: "grid",
    justifyItems: "end",
  },
}));

const DashboardListItem = ({ text, icon, percent, isSelected, className }) => {
  const classes = useStyles({ isSelected });
  const theme = useTheme();
  const Icon = useIcons(icon ?? "");
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={`${classes.item} ${className}`}
    >
      <Grid
        container
        alignItems="center"
        item
        xs={icon ? 5 : 9}
        sm={icon ? 5 : 9}
        md={icon ? 5 : 9}
        lg={icon ? 5 : 9}
        style={{ flexWrap: "nowrap" }}
      >
        <DashboardTitle
          variant={isSelected ? "dark.accent" : "grey3"}
          fontWeight={500}
          fontSize={17}
          text={text}
        />
        {icon && (
          <Icon
            fontSize="large"
            style={{
              color: isSelected ? "#00B9E1" : "#CAD5E8",
              margin: "auto",
              marginRight: 0,
            }}
          />
        )}
      </Grid>
      <Grid
        container
        item
        xs={icon ? 7 : 3}
        sm={icon ? 7 : 3}
        md={icon ? 7 : 3}
        lg={icon ? 7 : 3}
        className={classes.percent}
      >
        <DashboardTitle
          variant="grey3"
          fontWeight={isSelected ? 600 : 500}
          fontSize={isSelected ? 24 : 17}
          text={`${percent}`}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardListItem;
