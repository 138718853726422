import React from 'react'
import { CancelRounded } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import "dropzone/dist/min/dropzone.min.css"
import "react-dropzone-component/styles/filepicker.css"
import { toAbsoluteUrl } from '../../_metronic/_helpers';

export const DropFiles = ({ setterFunction, field, value, type = "*", message="Drop files to attach, or click" }) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: type,
    multiple: false,
    onDrop: acceptedFiles => {
      field ? setterFunction(field, acceptedFiles[0]) : setterFunction(acceptedFiles[0])
    }
  });

  const deleteFile = () => field ? setterFunction(field, null) : setterFunction(null)
  
  return (
    <section id="kt_dropzone_1" className="dropzone dropzone-default needsclick">
      <div className="dropzone-msg dz-message needsclick" {...getRootProps()}>
          <input id="files_drop_zone" {...getInputProps()}/>
        <p className="dropzone-msg-title" style={{ display: value !== null && value ? "none" : "block" }}><span className="flaticon-upload-1" />{message}</p>
      </div>
      {value !== null && value ?
        <div className="dz-preview">
            <div className="dz-image">
              <img width="100%" src={toAbsoluteUrl("/media/assets/default-image-file.png")} alt="default_file"/>
            </div>
            <div className="dz-remove" onClick={(e) => {e.preventDefault() ;deleteFile()}}>
              <CancelRounded id="remove_preview_file" fontSize="large" style={{ width: "2em", height: "2em" }} />
            </div>
        </div>
        : null}
    </section>
  );
}

export const DropMultipleFiles = ({setterFunction, field, files, type = "*"}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    multiple: true,
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => Object.assign(file, {
        src: URL.createObjectURL(file)
      }))
      setterFunction(field, [...files, ...acceptedFiles])
    }
  });

  function deleteFile (i) {
    let newFiles = files
    newFiles.splice(i, 1)
    setterFunction(field, newFiles)
  }

  return (
    <div id="kt_dropzone_1" className="dropzone dropzone-multi needsclick">
        <div className="dropzone-msg dz-message needsclick" {...getRootProps()}>
          <input id="multi_files_drop_zone" {...getInputProps()}/>
          <h3 className="dropzone-msg-title"><span className="flaticon-upload-1" /> Drop files to attach, or click</h3>
        </div>
        {files.length > 0 ? files.map((file, i) => (
        <div className="dropzone-item">
            <div className="dropzone-file" key={file.size}>
              <div className="dz-preview">
                {file.type.includes("image/", 0)
                  ? <img width="100%" src={file !== null ? file.src ? file.src : file : ""} alt="image_preview"/>
                  : <img width="100%" src={toAbsoluteUrl("media/assets/default-image-file.png")} alt="default_file"/>
                }
                <div id={`remove_preview_file_${i}`} className="dz-remove" onClick={(e) => {e.preventDefault() ;deleteFile(i)}}>
                  <CancelRounded fontSize="large" style={{ width: "2em", height: "2em" }} />
                </div>
              </div>
              <div className="dz-details">
                <div className="dz-filename">
                  <span>{file.name}</span>
                </div>
              </div>
            </div>
        </div>
        )) : null}
      </div>
  )
}