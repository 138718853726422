import React, { useState } from 'react'
import { Select, Input, MenuItem, Switch, Dialog, DialogContent, DialogActions, Grid, TextField, Button, DialogTitle, Divider, CircularProgress } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Search } from '../../../utils/Search'
import { DropNCrop } from '../../../utils/DropNCrop'

import { deleteStaffMember, saveStaffMembers, updateStaffMembers } from '../../../crud/api/staff_members.crud'
import { searchStaffType } from '../../../crud/api/staff_type.crud'
import * as staffMDUCK from "../../../../redux/ducks/staff_members.duck"
import { setLoader } from '../../../../redux/ducks/load.duck';
import MaskedPhoneInput from '../../../utils/MaskedPhoneInput'
import { setAlert } from '../../../../redux/ducks/alert.duck'
import { Delete } from '@material-ui/icons'
import { FormControlLabel } from '@material-ui/core'

const contributing_factors = [
  { key: 1, name: "Academics" },
  { key: 2, name: "Athletics" },
  { key: 3, name: "Counselors" },
];
const StaffDialog = ({ mode, setDialog, school, staffMember, staffMActions, settingLoader, setAlert, staff_member_admin, is_admin }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Staff" : "New Staff"
  const [loading, setLoading] = useState(false);

  const create = async (values) => {
    const newStaff = {
      ...values,
      school_id: school.id,
    }
    try {
      await saveStaffMembers(staffMActions.updateStaffMembers, newStaff)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  const update = async values => {
    const newStaff = {
      ...values,
      school_id: school.id,
      //staff_type_id: values.staff_type.id,
    }
    try {
      await updateStaffMembers(staffMActions.updateStaffMembers, newStaff, staffMember.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteStaff = (id) => {
    setAlert({
      title: "Delete Staff Member",
      message: "Are you sure to permanently delete this student?",
      btn_msg: "Delete Staff Member",
      action: () => deleteStaffMember(staffMActions.deleteStaffMembers, id).then(() => setDialog("close"))
    })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => setDialog("close")}
      id="staff_member_dialog"
      >
        <DialogTitle>{title}</DialogTitle>
        <Formik
          initialValues={{
            status: true,
            first_name: staffMember.user && staffMember.user.first_name ? staffMember.user.first_name : "",
            last_name: staffMember.user && staffMember.user.last_name ? staffMember.user.last_name : "",
            email: staffMember.user && staffMember.user.email ? staffMember.user.email : "",
            phone_number: staffMember.user && staffMember.user.phone_number ? staffMember.user.phone_number : "",
            image: staffMember.user && staffMember.user.avatar_thumb ? staffMember.user.avatar_thumb : null,
            name_prefix: staffMember.name_prefix ?? "",
            short_bio: staffMember.short_bio ?? "",
            job_title: staffMember.job_title ?? "",
            display_in_telehelp: staffMember.display_in_telehelp,
            faculty_group: staffMember.faculty_group ? staffMember.faculty_group : "",
          }}
          validate={values => {
            const expression = /\S+@\S+/
            const errors = {}
            if (!values.first_name) errors.first_name = 'Required field'
            if (!values.last_name) errors.last_name = 'Required field'
            if (!values.email) errors.email = 'Required field'
            if (!expression.test(values.email)) errors.email = 'Invalid email format'

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            setLoading(true)
            staffMember.id ? update(values) : create(values)
            setSubmitting(false)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} className="form-group">
                  <Grid container item xs={8} spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        id="staff_member_name_prefix_input_dialog"
                        variant="outlined"
                        placeholder="Dr."
                        label="Prefix"
                        name="name_prefix"
                        value={values.name_prefix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name_prefix && errors.name_prefix}
                        error={Boolean(touched.name_prefix && errors.name_prefix)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        id="staff_member_first_name_input_dialog"
                        variant="outlined"
                        label="First Name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.first_name && errors.first_name}
                        error={Boolean(touched.first_name && errors.first_name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        id="staff_member_last_name_input_dialog"
                        variant="outlined"
                        label="Last Name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.last_name && errors.last_name}
                        error={Boolean(touched.last_name && errors.last_name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        type="email"
                        id="staff_member_email_input_dialog"
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MaskedPhoneInput disabled={!(staff_member_admin || is_admin)} value={values.phone_number} handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        type="text"
                        id="staff_member_job_title_input_dialog"
                        variant="outlined"
                        placeholder="Student Counselor"
                        label="Role"
                        name="job_title"
                        value={values.job_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.job_title && errors.job_title}
                        error={Boolean(touched.job_title && errors.job_title)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        multiline
                        rows={2}
                        rowsMax={4}
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        type="text"
                        placeholder="I’m happy to support students navigate any academic concerns."
                        id="staff_member_short_bio_input_dialog"
                        variant="outlined"
                        label="Quote"
                        name="short_bio"
                        value={values.short_bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.short_bio && errors.short_bio}
                        error={Boolean(touched.short_bio && errors.short_bio)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className="d-flex align-items-center">
                      <FormControlLabel
                        style={{ minWidth: 180 }}
                        label="Display in Telehelp"
                        labelPlacement="start"
                        control={<Switch                          
                          id="staff_member_display_in_telehelp_input_dialog"
                          name="display_in_telehelp"
                          checked={values.display_in_telehelp}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          color="primary"
                        />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} style={{ alignItems: 'center'}}>
                      <h6 style={{ marginTop: 10}}>
                        Telehelp Group
                      </h6>
                      <Select
                        disabled={!values.display_in_telehelp}
                        required={values.display_in_telehelp}
                        fullWidth
                        id="staff_member_faculty_group_input_dialog"
                        label="Telehelp Group"
                        name="faculty_group"
                        value={values.faculty_group || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.contributing_factors_id &&
                          errors.contributing_factors_id
                        }
                        error={Boolean(
                          touched.contributing_factors_id &&
                            errors.contributing_factors_id
                        )}
                      >
                        {contributing_factors.map((fa) => (
                          <MenuItem key={fa.name} value={fa.name}>
                            {fa.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container item xs={4}>
                    <Grid item xs={12}>
                      <h5 style={{ position: "absolute", marginTop: -25 }}>Staff Member Picture</h5>
                    </Grid>
                    <Grid item xs={12}>
                      <DropNCrop disabled={!(staff_member_admin || is_admin)} setterFunction={setFieldValue} file={values.image}/>
                    </Grid>
                  </Grid>

                  <Grid item style={{display:'none'}}>
                    <TextField
                      fullWidth
                      disabled
                      id="staff_member_school_input_dialog"
                      variant="outlined"
                      label="School"
                      name="school"
                      defaultValue={school && school !== null && school.name && school.name}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={staffMember.id && "justify-content-between"}>
                {(staffMember.id && (staff_member_admin || is_admin)) && <button id="delete_staff_member_btn_dialog" type="reset" className="btn btn-danger" onClick={() => deleteStaff(staffMember.id)}>
                  <Delete /> Delete Staff Member
                </button>}
                <div className={(staff_member_admin || is_admin) ? "d-flex" : 'd-flex justify-content-end w-100'} >
                  {(staff_member_admin || is_admin) ? <><Button id="cancel_staff_member_btn_dialog" onClick={() => setDialog("close")} color="secondary">
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    id="submit_staff_member_btn_dialog"
                    disabled={isSubmitting || !values.first_name || !values.last_name || !values.email  || (!school || school === null)}
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                    {mode === "editing" ? "Submit" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                  </> : (<button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                      Close
                  </button>)}
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  school: store.school.selected_school,
  staffMember: store.staffM.staffMember,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  staffMActions: bindActionCreators(staffMDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffDialog)
