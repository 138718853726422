import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import Dashboard from "./pages/Dashboard";
import UserWrapper from "./pages/User/UserWrapper";
import SchoolWrapper from "./pages/School/SchoolWrapper";
import GoalsWrapper from "./pages/Goals/GoalsWrapper";
import ConfigWrapper from "./pages/Config/ConfigWrapper";
import CalendarWrapper from "./pages/Calendar/CalendarWrapper";
import AnnouncementsWrapper from "./pages/Announcement/AnnouncementsWrapper";
import DailyPicksWrapper from "./pages/DailyPicks/DailyPicksWrapper";
import ContentsWrapper from "./pages/Contens/ContentsWrapper";
import Teams from "./pages/Teams/Teams";
import Notifications from "./pages/Notifications/Notifications";
import Polls from "./pages/Polls/Polls";

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/users" component={UserWrapper} />
        <ContentRoute path="/teams" component={Teams} />
        {user.is_admin && (
          <ContentRoute path="/schools" component={SchoolWrapper} />
        )}
        <ContentRoute path="/goals" component={GoalsWrapper} />
        {user.is_admin && (
          <ContentRoute path="/contents" component={ContentsWrapper} />
        )}
        <ContentRoute path="/config" component={ConfigWrapper} />
        <ContentRoute path="/announcements" component={AnnouncementsWrapper} />
        <ContentRoute path="/notifications" component={Notifications} />
        <ContentRoute path="/polls" component={Polls} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
