import React, { useEffect, useState } from 'react'
import { Button, MuiThemeProvider, Paper, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'

import FilterDataTable from '../../../utils/FilterDataTable';
import ContentAuthorsDialog from './ContentAuthorsDialog'
import { tableMuiTheme } from '../../table/table_styles'

import { defaultContentAuthor, getContentAuthor, getContentAuthors } from '../../../crud/api/content_authors.crud'

import { setLoader } from '../../../../redux/ducks/load.duck'
import * as contentAuthorDUCK from "../../../../redux/ducks/content_author.duck"

const ContentAuthors = ({ settingLoader, content_authors, caActions }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (query && query.length > 1) getContentAuthors(caActions.setContentAuthors, 1, query)

    else getContentAuthors(caActions.setContentAuthors)
    
  }, [caActions.setContentAuthors, query])

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'logo_thumb',
      label: 'Logo',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value && (
          <div style={{ background: `url(${value}) center center / cover no-repeat`, height: 40, width: 40, borderRadius: "50%" }}/>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: content_authors.rowsPerPage,
    page: content_authors.page,
    count: content_authors.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "content_authors.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: (data, meta, col) => {
      openDialog('editing', data[0])
    },
    onChangePage: (mPage) => {
      settingLoader(true)
      getContentAuthors(caActions.setContentAuthors, mPage +1, query)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          settingLoader(true)
          setDialog(mode)
          caActions.setContentAuthor(defaultContentAuthor)
          break;
        case "editing":
          settingLoader(true)
          getContentAuthor(caActions.setContentAuthor, id)
          .then(() => setDialog(mode))
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            caActions.setContentAuthor(defaultContentAuthor)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_content_author_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New content author</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            title="Staff"
            columns={columns}
            options={options}
            data={content_authors.data}
          />
        </MuiThemeProvider>
      </Paper>
      <ContentAuthorsDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = store => ({
  content_authors: store.ca.content_authors,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  caActions: bindActionCreators(contentAuthorDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentAuthors)
