import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Autorize: "[Logout] Autorize",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload.data;
        return { authToken, user: user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        window.location.reload()
        localStorage.clear()
        return initialAuthState;
      }

      case actionTypes.Autorize: {
        return {
          ...state,
          user: {
            ...state.user,
            staff_member_admin: action.payload,
          }
        }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: data => ({ type: actionTypes.Login, payload: { data } }),
  logout: () => ({ type: actionTypes.Logout }),
  // Checks if user is staff_member.is_admin
  autorize: isStaffMember => ({ type: actionTypes.Autorize, payload: isStaffMember }),
};
