import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultConference } from '../../app/crud/api/conference.crud';

export const actionType = {
  GetConferences: '[CONFERENCE] GET_CONFERENCES',
  GetConference: '[CONFERENCE] GET_CONFERENCE',
  UpdateConference: '[CONFERENCE] UPDATE_CONFERENCE',
  LoadConferenceCsv: '[CONFERENCE] LOAD_CONFERENCE_CSV',
  SelectConference: '[CONFERENCE] SELECT_CONFERENCE'
}

const initialState = {
  conferences: { data: [], isFetched: false },
  conference: defaultConference,
  selected_conference: null
}

export const reducer = persistReducer(
  { storage, key: 'conf', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetConferences: {
        return { ...state, conferences: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateConference: {
        let newData = state.conferences.data
        const index = state.conferences.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.conferences.data]
        } else {
          newData = state.conferences.data
          newData[index] = action.payload
        }

        return { ...state, conferences: { ...state.conferences, data: newData } }
      }
      
      case actionType.GetConference: {
        
        return { ...state, conference: action.payload }
      }

      case actionType.LoadConferenceCsv: {
        let newData =  action.payload
        
        return { ...state, conferences: { ...state.conferences, data: [...state.conferences.data, ...newData] } }
      }

      case actionType.SelectConference: {

        return { ...state, selected_conference: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setConferences: data => ({ type: actionType.GetConferences, payload: data }),
  setConference: data => ({ type: actionType.GetConference, payload: data }),
  updateConferences: data => ({ type: actionType.UpdateConference, payload: data }),
  loadConferenceCsv: data => ({ type: actionType.LoadConferenceCsv, payload: data }),
  setSelectedConference: state => ({ type: actionType.SelectConference, payload: state })
}