import React from 'react'
import { Button, InputLabel } from '@material-ui/core';
import { CancelRounded, Publish } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import "dropzone/dist/min/dropzone.min.css"
import "react-dropzone-component/styles/filepicker.css"
import { toAbsoluteUrl } from '../../_metronic/_helpers';

export const FileDropInput = ({ setterFunction, field, value, type = "*", message="Drop files to attach, or click" }) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: type,
    multiple: false,
    onDrop: acceptedFiles => {
      field ? setterFunction(field, acceptedFiles[0]) : setterFunction(acceptedFiles[0])
    }
  });

  const deleteFile = () => field ? setterFunction(field, null) : setterFunction(null)
  return (
    <section id="kt_dropzone_1" className="d-flex align-items-end">
      <Button 
        id="upload_csv_bulk_student_btn"
        variant="contained" 
        color="secondary" 
        startIcon={<Publish />}
        >
          <input id="files_drop_zone" {...getInputProps()} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }}/>
          {message}
        </Button>
        {value !== null && value && <InputLabel className="ml-1">{value.name}</InputLabel>}
    </section>
  );
}