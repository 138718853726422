import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import * as dailyPicksDUCK from "../../../redux/ducks/daily_picks.duck"
import { setLoader } from '../../../redux/ducks/load.duck';
import DailyPickContentSelector from './DailyPickContentSelector';
import { saveDailyPick, updateDailyPick } from '../../crud/api/daily_picks.crud';

const DailyPicksDialog = ({ mode, setDialog, goal_id, daily_pick, settingLoader, dpActions }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Daily Pick" : "New Daily Pick"
  const [loading, setLoading] = useState(false);
  const location = useLocation()

  const zone_id = location.pathname.includes("mind") ? 1 : location.pathname.includes("body") ? 2 : 3

  const create = async values => {
    settingLoader(true)
    const data = {
      display_order: values.display_order,
      content_id: values.content.id,
      student_zone_id: zone_id,
      goal_id: goal_id
    }
    try {
      await saveDailyPick(dpActions.updateDailyPicks, data)
      setLoading(false)
      setDialog("close")
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {
    settingLoader(true)
    const data = {
      display_order: values.display_order,
      content_id: values.content.id,
      student_zone_id: zone_id,
      goal_id: goal_id
    }
    try {
      await updateDailyPick(dpActions.updateDailyPicks, data, daily_pick.id)
      setLoading(false)
      setDialog("close")
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setDialog("close")} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          ...daily_pick
        }}
        validate={values => {
          var regex = /[0-9]|\./
          const errors = {}
          if (values.content === null) errors.content = 'Required field'
          if (!values.display_order) errors.display_order = 'Required field'
          if (!regex.test(values.display_order)) errors.display_order = 'Order must be integer'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            daily_pick.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DailyPickContentSelector setterFunction={setFieldValue} value={values.content} error={touched.content && errors.content} />
                </Grid>
                <Grid item xs={12}>
                  <h6>Choose the display order for this content</h6>
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    id="daily_pick_order_input_dialog"
                    variant="outlined"
                    name="display_order"
                    value={values.display_order}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.display_order && errors.display_order}
                    error={Boolean(touched.display_order && errors.display_order)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button id="cancel_daily_pick_btn_dialog" onClick={() => setDialog("close")} color="secondary">
                Cancel
              </Button>
              <button
                type="submit"
                id="submit_daily_pick_btn_dialog"
                disabled={isSubmitting || !values.content === null || Boolean(touched.display_order && errors.display_order)}
                className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                {mode === "editing" ? "Submit" : "Create"}
                {loading && <CircularProgress color="inherit" size={18} className="ml-5" />}
              </button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => {
  return ({
  daily_pick: store.dp.daily_pick,
  goal_id: store.goal_t.selected_goal_type,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  dpActions: bindActionCreators(dailyPicksDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyPicksDialog)
