import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultFeeling } from '../../app/crud/api/feelings.crud';

export const actionType = {
  GetFeelings: '[FEELING] GET_FEELINGS',
  GetFeeling: '[FEELING] GET_FEELING',
  UpdateFeeling: '[FEELING] UPDATE_FEELING',
  LoadFeelingCsv: '[FEELING] LOAD_FEELING_CSV',
  SelectFeeling: '[FEELING] SELECT_FEELING'
}

const initialState = {
  feelings: { data: [], isFetched: false },
  feeling: defaultFeeling,
  selected_feeling: null
}

export const reducer = persistReducer(
  { storage, key: 'feel', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetFeelings: {
        return { ...state, feelings: { data: action.payload.items.map(x => ({...x.feeling, content: x.content })), count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateFeeling: {
        let newData = state.feelings.data
        const index = state.feelings.data.findIndex(x => x.id === action.payload.id)
        
        if (index === -1) {
          newData = [action.payload, ...state.feelings.data]
        } else {
          newData = state.feelings.data
          newData[index] = action.payload
        }

        return { ...state, feelings: { ...state.feelings, data: newData } }
      }
      
      case actionType.GetFeeling: {
        
        return { ...state, feeling: action.payload }
      }

      case actionType.LoadFeelingCsv: {
        let newData =  action.payload
        
        return { ...state, feelings: { ...state.feelings, data: [...state.feelings.data, ...newData] } }
      }

      case actionType.SelectFeeling: {

        return { ...state, selected_feeling: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setFeelings: data => ({ type: actionType.GetFeelings, payload: data }),
  setFeeling: data => ({ type: actionType.GetFeeling, payload: data }),
  updateFeelings: data => ({ type: actionType.UpdateFeeling, payload: data }),
  loadFeelingCsv: data => ({ type: actionType.LoadFeelingCsv, payload: data }),
  setSelectedFeeling: state => ({ type: actionType.SelectFeeling, payload: state })
}