import React, { useState, useEffect } from 'react'
import { searchConference } from '../../crud/api/conference.crud';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';

const SConfereceSelector = ({ conference, setFieldValue, error }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("")
  const loading = open && options.length === 0;

  useEffect(() => {

    if (!loading) {
      return undefined;
    }

    if (open) searchConference(search, setOptions)
    

  }, [open, loading, search])

  useEffect(() => {
    if (!open) setOptions([])

  }, [open])

  return (
    <Autocomplete
      id="school_conference_input_dialog"
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      value={conference}
      inputValue={search}
      onChange={(e, newValue) => setFieldValue("conference", newValue)}
      onInputChange={(e, newInputValue) => setSearch(newInputValue)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option && option.name ? option.name : ""}
      renderInput={params => (
        <TextField
          {...params}
          label="Conference"
          variant="outlined"
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default SConfereceSelector
