import React, { useState } from 'react'
import { Paper, MuiThemeProvider, Button, useMediaQuery } from '@material-ui/core'
import MUIDataTable from "mui-datatables";

import { tableMuiTheme, users_data } from "../../table/table_styles"
import AdminDialog from './AdminDialog';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers/AssetsHelpers';

const Admin = () => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'image',
      label: 'Image',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div style={{ background: `url(${toAbsoluteUrl(value)}) center center / cover no-repeat`, height: 40, width: 40, borderRadius: "50%", margin: "-5px 0" }}/>
        )
      }
    },
    {
      name: 'first_name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'last_name',
      label: 'Last name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      }
    },
  ]

  const options = {
    serverSide: false,
    rowsPerPageOptions: [],
    rowsPerPage: 15,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "admin.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: data => {
      openDialog('editing', data)
    }
  };

  const openDialog = (mode, product = null) => {
    switch(mode){
        case "create":
            setDialog(mode)
            break;
        case "editing":
            setDialog(mode)
            break;
        case "close":
            setDialog(mode)
            break;
        default:
            console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="text-right pb-4 px-5">
        <Button variant="contained" color="secondary" onClick={() => openDialog("create")}>New admin</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            title="Admin"
            columns={columns}
            data={users_data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <AdminDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

export default Admin
