import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react'
import { CONTENTS_URL } from '../../crud/helpers/routes';
import { getMultipartParams, handleResponse } from '../../crud/helpers/validate';

const DailyPickContentSelector = ({ setterFunction, value, error }) => {
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("")

  const getContents = (setterFunction) => {
    return fetch(`${CONTENTS_URL}?per_page=99999`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction([...options, ...json.items]))
    .catch(e => console.log(e))
  }

  useEffect(() => {
    getContents(setOptions)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete
      id="daily_pick_content_input_dialog"
      fullWidth
      options={options}
      value={value}
      inputValue={search}
      onChange={(e, newValue) => setterFunction("content", newValue)}
      onInputChange={(e, newInputValue) => setSearch(newInputValue)}
      getOptionSelected={(option, value) => (option.name === value.name) || (option.author === value.author) || (options.author_name === value.author_name)}
      getOptionLabel={(option) => `${option.name} - ${option.author} - ${option.author_name}`}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Content - Author - Sponsor"
          label="Select a content"
          variant="outlined"
          error={Boolean(error)}
          helperText={error}
        />
      )}
    />
  )
}


export default DailyPickContentSelector
