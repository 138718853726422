/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Paper, MuiThemeProvider, Button, useMediaQuery, Tooltip } from '@material-ui/core'
import MUIDataTable from "mui-datatables";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actionTableMuiTheme } from '../table/table_styles';
import FilterDataTable from '../../utils/FilterDataTable';
import SchoolDialog from './SchoolDialog';

import * as schoolDUCK from "../../../redux/ducks/school.duck"
import { getSchools, defaultSchool, getSchool } from '../../crud/api/schools.crud';
import { setLoader } from '../../../redux/ducks/load.duck';
import { Add } from '@material-ui/icons';

const School = ({ schools, schoolActions, settingLoader }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (query.length > 1) getSchools(schoolActions.setSchools, 1, query)
    else if (!query) getSchools(schoolActions.setSchools, 1)
  }, [query, schoolActions.setSchools])
  
  const columns = [
    {
      name: 'active',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'mascot_name',
      label: 'School mascot',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'tier',
      label: 'Tier',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <>
            {value && value.name ? value.name : ""}
          </>
        )
      }
    },
    {
      name: 'primary_contact',
      label: 'Contact',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <Tooltip title="Edit School" placement="top">
            <a id={`edit_school_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog('editing', value)}>
              <i className="fas fa-edit text-primary" />
            </a>
          </Tooltip>
        )
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: schools.rowsPerPage,
    page: schools.page,
    count: schools.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "school.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: mPage => {
      getSchools(schoolActions.setSchools, mPage +1)
      settingLoader(true)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
      case "create":
          settingLoader(true)
          schoolActions.setSchool(defaultSchool)
          setDialog(mode)
          break;
        case "editing":
          settingLoader(true)
          getSchool(schoolActions.setSchool, id)
          .then(() => setDialog(mode))
          .catch(() => {})
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            schoolActions.setSchool(defaultSchool)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  return (
    <>
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_school_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New School</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title="Admin"
            columns={columns}
            data={schools.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <SchoolDialog mode={dialog} setDialog={openDialog} />
    </>
  )
}

const mapStateToProps = store => ({
  schools: store.school.schools
})

const mapDispatchToProps = (dispatch) => ({
  schoolActions: bindActionCreators(schoolDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(School)
