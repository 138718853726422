import React from 'react'
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask';

const InputMaskCustom = props => {
  const { inputRef, value, onChange } = props;
    return (
      <InputMask
        {...props}
        type="tel"
        inputRef={inputRef}
        mask="+1 (999) 999 9999"
        variant="outlined"
        name="phone_number"
        value={value}
        onChange={onChange}
      />
    )
}

const MaskedPhoneInput = ({value, handleChange, disabled}) => {
  
  return (
    <TextField
      disabled={disabled}
      fullWidth
      id="phone_number_input"
      label="Phone"
      variant="outlined"
      name="phone_number"
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: InputMaskCustom}}
    />
  )
}

export default MaskedPhoneInput
