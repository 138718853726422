const { createMuiTheme } = require("@material-ui/core");

export const tableMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        cursor: "pointer",
        padding: "4px 10px",
        fontSize: "1rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // eslint-disable-next-line no-useless-computed-key
        ['@media(min-width: 960px)']: {
          maxWidth: 200,
        }
      }
    },
    MUIDataTableToolbar: {
      root: {
        background: "linear-gradient(125deg, rgba(17, 151, 192, 1) 25%, rgba(4, 7, 25, 1) 100%)",
        minHeight: "50px",
        height: "50px",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
        color: 'white'
      },
      icon: {
        color: 'white',

        '&:hover': {
          color: "#c4c4c4"
        },
      },
      iconActive: {
        color: 'white',

        '&:hover': {
          color: "#c4c4c4"
        },
      },
      titleRoot: {
        display: 'none'
      }
    },
    MUIDataTableSearch: {
      searchIcon: {
        color: "white"
      },
      clearIcon: {
        color: "white"
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 90
      }
    },
    MuiInputBase: {
      root: {
        color: "white"
      }
    }
  }
})

export const actionTableMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableToolbar: {
      root: {
        background: "linear-gradient(125deg, rgba(17, 151, 192, 1) 25%, rgba(4, 7, 25, 1) 100%)",
        minHeight: "50px",
        height: "50px",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
        color: 'white'
      },
      icon: {
        color: 'white',

        '&:hover': {
          color: "#c4c4c4"
        },
      },
      iconActive: {
        color: 'white',

        '&:hover': {
          color: "#c4c4c4"
        },
      },
      titleRoot: {
        display: 'none'
      }
    },
    MUIDataTableSearch: {
      searchIcon: {
        color: "white"
      },
      clearIcon: {
        color: "white"
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 90
      }
    },
    MUIDataTableBodyCell: {
      root: {
        padding: "4px 10px",
        fontSize: "1rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // eslint-disable-next-line no-useless-computed-key
        ['@media(min-width: 960px)']: {
          maxWidth: 200,
        }
      }
    },
    MuiInputBase: {
      root: {
        color: "white"
      }
    }
  }
})

export const users_data = [
  { id: 1, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 2, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 3, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 4, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 5, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 6, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 7, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 8, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 9, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 10, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 11, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 12, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 13, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 14, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 15, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 16, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 17, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 18, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 19, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
  { id: 20, image: "media/users/100_14.jpg", status: false, first_name: "Adrien", last_name: "jhons", nick_name: "mr A", email: "adr@the_zone.com", expertise: "Therapist", school: "UIA", teams: "Lions", phone: "6589463159", code: "65A" },
]

export const autocomplete_data = [
  { id: 1, title: 'Student' },
  { id: 2, title: 'Body' },
  { id: 3, title: 'Life' },
  { id: 4, title: 'Mind' }
]