import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultSport } from '../../app/crud/api/sports.crud';

export const actionType = {
  GetSports: '[SPORT] GET_SPORTS',
  GetSport: '[SPORT] GET_SPORT',
  UpdateSport: '[SPORT] UPDATE_SPORT',
  LoadSportCsv: '[SPORT] LOAD_SPORT_CSV',
  SelectSport: '[SPORT] SELECT_SPORT'
}

const initialState = {
  sports: { data: [], isFetched: false },
  sport: defaultSport,
  selected_sport: null
}

export const reducer = persistReducer(
  { storage, key: 'sport', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetSports: {
        return { ...state, sports: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateSport: {
        let newData = state.sports.data
        const index = state.sports.data.findIndex(x => x.sport.id === action.payload.sport.id)

        if (index === -1) {
          newData = [action.payload, ...state.sports.data]
        } else {
          newData = state.sports.data
          newData[index] = action.payload
        }

        return { ...state, sports: { ...state.sports, data: newData } }
      }
      
      case actionType.GetSport: {
        
        return { ...state, sport: action.payload }
      }

      case actionType.LoadSportCsv: {
        let newData =  action.payload
        
        return { ...state, sports: { ...state.sports, data: [...state.sports.data, ...newData] } }
      }

      case actionType.SelectSport: {

        return { ...state, selected_sport: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setSports: data => ({ type: actionType.GetSports, payload: data }),
  setSport: data => ({ type: actionType.GetSport, payload: data }),
  updateSports: data => ({ type: actionType.UpdateSport, payload: data }),
  loadSportCsv: data => ({ type: actionType.LoadSportCsv, payload: data }),
  setSelectedSport: state => ({ type: actionType.SelectSport, payload: state })
}