import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  Checkbox,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { connect } from "react-redux";

import { saveNotification } from "../../crud/api/notifications.crud";
import { bindActionCreators } from "redux";
import { setAlert } from "../../../redux/ducks/alert.duck";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Autocomplete } from "@material-ui/lab";
import { SCHOOL_URL } from "../../crud/helpers/routes";
import {
  getMultipartParams,
  handleResponse,
} from "../../crud/helpers/validate";
import { searchStudents } from "../../crud/api/students.crud";

const Notifications = ({ school, setMsg }) => {
  const TITLE_CHARACTER_LIMIT = 40;
  const BODY_CHARACTER_LIMIT = 144;
  const isDesktop = useMediaQuery("(min-width: 600px)");

  const initialState = {
    title: "",
    body: "",
    loader: false,
    teams_id: [],
    students_id: [],
    key: "",
    all_teams: false,
  };

  const [state, setState] = useState(initialState);

  const [teams, setTeams] = useState([]);
  const [students, setStudents] = useState([]);
  const query = "";

  const getTeams = (setterFunction, slug) => {
    return fetch(
      `${SCHOOL_URL}/${slug}/teams?per_page=100`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json.items))
      .catch((e) => {
        console.error(e.errors);
        return e;
      });
  };

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null)
      getTeams(setTeams, school.slug);
  }, [school]);

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1)
        searchStudents(setStudents, school.slug, query);
      else searchStudents(setStudents, school.slug, "");
    }
  }, [query, school, setStudents]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const createNotification = () => {
    if (school && school !== null && school.slug)
      setState({ ...state, loader: true });
    setTimeout(() => {
      const { key, ...data } = state;
      saveNotification(data, school.slug)
        .then(() => setState({ ...initialState, key: Math.random.toString() }))
        .catch(() => setState({ ...state, loader: false }));
    }, 1000);
  };

  const handleAllTeamsChange = (e, v) => {
    setState({ ...state, all_teams: v });
  };

  return (
    <Paper className="py-5 px-8 m-6">
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={4}>
          <h3 className="mb-2">Live Preview</h3>
          <div
            className="blured-xs-box px-3 py-2"
            style={{ minWidth: 150, maxHeight: 250 }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-end mb-2">
                <img
                  src={toAbsoluteUrl("/media/logos/logo_contained.png")}
                  alt="logo"
                  width="18px"
                  className="mr-3 rounded"
                />
                THE ZONE
              </div>
              <p>Now</p>
            </div>
            <div>
              <h3>
                {state.title.length === 0
                  ? "Title has a 40-character limit"
                  : state.title}
              </h3>
              <h6 className="font-weight-light">
                {state.body.length === 0
                  ? 'Body has a 144-character limit. PLEASE use the "ANNOUNCEMENTS" feature for sharing Rich Long Text, Links, Videos, and Docs'
                  : state.body}
              </h6>
            </div>
          </div>
        </Grid>
        <Divider
          orientation={isDesktop ? "vertical" : "horizontal"}
          flexItem={isDesktop}
          className={!isDesktop ? "w-100" : ""}
        />
        <Grid container item xs={12} sm={8} spacing={2}>
          <Grid item xs={12}>
            <h3 className="mb-2">Type Here</h3>
            <TextField
              inputProps={{
                maxLength: TITLE_CHARACTER_LIMIT,
              }}
              value={state.title}
              helperText={`Tip: A great "Title" is three things: Catchy, Short, and Insightful`}
              fullWidth
              required
              id="title_notifications_btn"
              variant="outlined"
              label={`Title | ${state.title.length}/${TITLE_CHARACTER_LIMIT}`}
              name="title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={{
                maxLength: BODY_CHARACTER_LIMIT,
              }}
              value={state.body}
              helperText={`NOTE: Use the "ANNOUNCEMENTS" feature for sharing Rich Long Text, Links, Videos, and Docs`}
              multiline
              required
              fullWidth
              rows={4}
              id="body_notifications_btn"
              variant="outlined"
              label={`Body | ${state.body.length}/${BODY_CHARACTER_LIMIT}`}
              name="body"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Checkbox
              id="all_teams_checbox"
              value={state.all_teams ? 1 : 0}
              checked={state.all_teams}
              name="all_teams"
              onChange={(e, v) => handleAllTeamsChange(e, v)}
            />{" "}
            Notifiy all teams in all schools
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              key={state.key}
              selectedAutocompleteValues={state.teams_id}
              id="tags-teams"
              fullWidth
              multiple
              options={teams}
              defaultValue={[]}
              getOptionLabel={(option) => option.name}
              disabled={state.all_teams}
              onChange={(event, val) => {
                const newTeamsId = val.map(({ id }) => id);
                setState({ ...state, teams_id: newTeamsId });
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" placeholder="Teams" />
              )}
            />
          </Grid>
          <div className="mt-2"></div>
          <Grid item xs={12}>
            <Autocomplete
              key={state.key}
              id="tags-students"
              fullWidth
              multiple
              options={students}
              defaultValue={[]}
              disabled={state.all_teams}
              getOptionLabel={(option) =>
                option.user.first_name + " " + option.user.last_name
              }
              onChange={(event, val) => {
                const newStudentsId = val.map(({ id }) => id);
                setState({ ...state, students_id: newStudentsId });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Students"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end pt-4 px-3">
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Send />}
          id="request_notifications_btn"
          onClick={() => createNotification()}
          disabled={!state.title || !state.body}
        >
          Send{" "}
          {state.loader && (
            <CircularProgress color="inherit" size={18} className="ml-3" />
          )}
        </Button>
      </div>
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  school: store.school.selected_school,
});

const mapDispatchToProps = (dispatch) => ({
  setMsg: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
