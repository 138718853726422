import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashboardChart from "./components/DashboardChart";
import {
  getStudentMoods,
  getTopMoodDay,
  getTopMoodWeek,
} from "../../crud/api/mood.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import DashboardTitle from "./components/DashboardTitle";
import TeamsList from "./components/TeamsList";
import DropdownMonths from "./components/DropdownMonths";
import RotatedBackground from "./components/RotatedBackground";
import DashboardCardList from "./components/DashboardCardList";
import TeleHelpNotifications from "./components/TelehelpNotifications";
import TeleHelpNotificationsSentCount from "./components/TeleHelpNotificationsSentCount";
import DashboardLibraryList from "./components/DashboardLibraryList";
import DashboardUniversityHub from "./components/DashboardUniversityHub";
import DashboardTextSlider from "./components/DashboardTextSlider";
import { useBreakpoints } from "../../hooks/useBreakpoint";
import DashboardFocusAreasListTop3 from "./components/DashboardFocusAreasListTop3";
import { useTraduction } from "../../hooks/useTraduction";
import DashboardDownloadDropdown from "./components/DashboardDownloadDropdown";
import DashboardFocusAreasList from "./components/DashboardFocusAreasList";
import DashboardFiltersDropdown from "./components/DashboardFiltersDropdown";
import DashboardChipInfoUsage from "./components/DashboardChipInfoUsage";
import DashboardChipInfoLaunches from "./components/DashboardChipInfoLaunches";
import DashboardChipActiveStudents from "./components/DashboardChipActiveStudents";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#040719",
    minHeight: "100%",
  },
  paginator: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#040719",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  headerContainer: {
    minHeight: 80,
  },
  chipsContainer: {
    justifyContent: "flex-end",
  },
  rightHeader: {
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
}));

const DashboardDesktop = ({ school, is_admin }) => {
  const classes = useStyles();
  const [data, setData] = useState({ labels: [], series: [] });
  const [topDay, setTopDay] = useState(null);
  const [topWeek, setTopWeek] = useState(null);

  const { t } = useTraduction();

  useBreakpoints();

  useEffect(() => {
    if (school && school !== null && school.slug) {
      getStudentMoods(school.slug, setData);
      getTopMoodDay(school.slug, setTopDay);
      getTopMoodWeek(school.slug, setTopWeek);
    }
  }, [school]);

  // Si todas las pantallas serán tema dark, conviene mover este container a un punto por encima de las rutas
  return (
    <div className={classes.container}>
      <Grid container className="p-8" spacing={2}>
        <Grid container item lg={12} className={classes.headerContainer}>
          <Grid container item lg={10}>
            <DashboardTitle
              fontWeight={500}
              fontSize={24}
              text={t("DASHBOARD.DASHBOARD")}
            />
          </Grid>
          <Grid container item lg={2}>
            <DashboardDownloadDropdown />
          </Grid>
        </Grid>
        <Grid container item lg={5}>
          <Grid className="mb-5" container item lg={5}>
            <DashboardTitle
              variant="dark.white"
              fontWeight={300}
              fontSize={24}
              text={t("DASHBOARD.CURRENTLY_VIEWING")}
            />
            <TeamsList />
          </Grid>
          <Grid container item lg={7}></Grid>
          <Grid container item lg={12}>
            <DashboardTitle
              variant="dark.white"
              fontWeight={500}
              fontSize={28}
              text={t("DASHBOARD.GENERAL_MOOD")}
            />
            <div style={{ zIndex: 2 }}>
              <DashboardChart labels={data.labels} series={data.series} />
            </div>
            <div
              style={{
                display: "flex",
                position: "relative",
                bottom: 130,
                zIndex: 1,
                width: "100%",
              }}
            >
              <RotatedBackground />
            </div>
            <span style={{ marginTop: -130 }}></span>
            <div style={{ marginTop: -130, width: "100%" }}>
              <DashboardCardList />
            </div>
            <div className="m-5"></div>
            <TeleHelpNotifications text={t("DASHBOARD.TELEHELP_DRAFTS_SENT")} />
            {is_admin && (
              <TeleHelpNotificationsSentCount
                text={t("DASHBOARD.TELEHELP_SENT_COUNT")}
              />
            )}
            <Grid item lg={12}>
              <DashboardTitle
                variant="dark.white"
                fontWeight={500}
                fontSize={28}
                text={t("DASHBOARD.FOCUS_AREAS")}
              />
            </Grid>
            <div className="pl-2">
              <DashboardTitle
                variant="dark.white"
                fontWeight={500}
                fontSize={17}
                text={t("DASHBOARD.TOP_3")}
              />
              <Grid className="mt-1" container item lg={12} spacing={2}>
                <Grid container item flex lg={7} alignContent="flex-start">
                  <DashboardFocusAreasListTop3 />
                </Grid>
                <Grid container item lg={5} alignContent="flex-start">
                  <DashboardFocusAreasList />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container item lg={7} spacing={3}>
          <Grid container item lg={12}>
            <Grid className={classes.rightHeader} container lg={12}>
              <Grid container item lg={3}>
                <Grid container item lg={2}></Grid>
                <Grid container item lg={10}>
                  <DashboardTitle
                    variant="grey2"
                    fontWeight={400}
                    fontSize={15}
                    text={t("DASHBOARD.MONTH")}
                  />
                  <Grid container item lg={12}>
                    <DropdownMonths />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item lg={4}>
                <Grid container item lg={12}>
                  <DashboardTitle
                    variant="grey2"
                    fontWeight={400}
                    fontSize={15}
                    text={t("DASHBOARD.WEEK")}
                  />
                  <Grid container item lg={12}>
                    <DashboardTextSlider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="justify-content-end" container item lg={3}>
                <DashboardFiltersDropdown />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.chipsContainer} container item lg={12}>
            <DashboardChipActiveStudents width={128} />
            {/* <div className="ml-3">
                        <DashboardChipInfoUsage width={128}/>
                    </div> */}
            <div className="ml-3">
              <DashboardChipInfoLaunches width={128} />
            </div>
          </Grid>
          <Grid container item lg={5}>
            <DashboardLibraryList />
          </Grid>
          <Grid container item lg={7}>
            <DashboardUniversityHub />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (store) => ({
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
});

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDesktop);
