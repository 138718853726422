import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  Typography,
  Switch,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  DialogTitle,
  FormControl,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Formik } from "formik";

import SConfereceSelector from "./SConfereceSelector";
import { DropNCrop } from "../../utils/DropNCrop";
// import { DropFiles } from '../../utils/DropFiles';

import * as schoolDUCK from "../../../redux/ducks/school.duck";
import { saveSchool, updateSchool } from "../../crud/api/schools.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import { GAutocomplete } from "../../utils/GAutocomplete";
import TimeZoneSelector from "./TimeZoneSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
  },
}));

const SchoolDialog = ({
  mode,
  setDialog,
  school,
  schoolActions,
  settingLoader,
}) => {
  const title = mode === "editing" ? "Edit School" : "New School";
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const open = mode === "editing" || mode === "create";
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const create = async (values) => {
    const newSchool = {
      conference_id:
        values.conference && values.conference.id ? values.conference.id : "",
      tier_id: values.tier_id,
      name: values.name,
      mascot_name: values.mascot_name,
      address: values.address,
      primary_contact: values.primary_contact,
      contact_email: values.contact_email,
      contact_number: values.contact_number,
      proof_of_concept: values.proof_of_concept,
      mascot_image: values.image,
      website_url: values.website_url,
      time_zone: values.time_zone,
    };
    try {
      await saveSchool(schoolActions.updateSchools, newSchool);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const newSchool = {
      conference_id:
        values.conference && values.conference.id ? values.conference.id : "",
      tier_id: values.tier_id,
      name: values.name,
      mascot_name: values.mascot_name,
      address: values.address,
      primary_contact: values.primary_contact,
      contact_email: values.contact_email,
      contact_number: values.contact_number,
      proof_of_concept: values.proof_of_concept,
      mascot_image: values.image,
      website_url: values.website_url,
      time_zone: values.time_zone,
    };
    try {
      await updateSchool(schoolActions.updateSchools, newSchool, school.id);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setDialog("close")}
      maxWidth="md"
      fullWidth
      id="school_dialog"
    >
      <Formik
        initialValues={{
          ...school,
          image: school.mascot_image_url ? school.mascot_image_url : "",
          tier_id: school.tier && school.tier.id ? school.tier.id : "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) errors.name = "Required field";
          if (!values.time_zone || values.time_zone === null)
            errors.time_zone = "Required field";

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          setTimeout(() => {
            school.id ? update(values) : create(values);
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <Typography variant="h6">{title}</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={8} spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="school_name_input_dialog"
                        label="School Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SConfereceSelector
                        conference={values.conference}
                        setFieldValue={setFieldValue}
                        error={touched.conference && errors.conference}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="school_school_mascot_input_dialog"
                        label="School mascot"
                        name="mascot_name"
                        value={values.mascot_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={4}>
                    <Grid item xs={12}>
                      <h5
                        style={{
                          position: "absolute",
                          top: isDesktop ? "10%" : "6%",
                        }}
                      >
                        Mascot picture
                      </h5>
                      <DropNCrop
                        setterFunction={setFieldValue}
                        file={values.image}
                        field="image"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GAutocomplete
                    initialValue={values.address}
                    setterFunction={setFieldValue}
                    error={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="school_web_site_input_dialog"
                    label="Web site"
                    name="website_url"
                    value={values.website_url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimeZoneSelector
                    time_zone={values.time_zone}
                    setFieldValue={setFieldValue}
                    helperText={touched.time_zone && errors.time_zone}
                    error={Boolean(touched.time_zone && errors.time_zone)}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="school_primary_contact_input_dialog"
                    label="Primary Contact"
                    name="primary_contact"
                    value={values.primary_contact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.contact && errors.contact}
                    error={Boolean(touched.contact && errors.contact)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    id="school_email_input_dialog"
                    label="Contact Email"
                    name="contact_email"
                    value={values.contact_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="school_contact_number_input_dialog"
                    label="Contact number"
                    name="contact_number"
                    value={values.contact_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Proof of concept"
                      labelPlacement="start"
                      control={
                        <Switch
                          id="school_proof_of_concept_input_dialog"
                          name="proof_of_concept"
                          checked={values.proof_of_concept}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={8}
                  justify="center"
                  alignItems="center"
                >
                  <FormControl
                    required
                    error={Boolean(touched.tier_id && errors.tier_id)}
                  >
                    <FormControlLabel
                      label="Tier"
                      labelPlacement="start"
                      control={
                        <ToggleButtonGroup
                          id="school_tier_input_dialog"
                          className={classes.root}
                          size="large"
                          value={values.tier_id}
                          exclusive
                          onChange={(e, value) =>
                            setFieldValue("tier_id", value)
                          }
                        >
                          <ToggleButton
                            id="school_set_tier_silver_btn"
                            value={1}
                          >
                            Silver
                          </ToggleButton>
                          <ToggleButton id="school_set_tier_gold_btn" value={2}>
                            Gold
                          </ToggleButton>
                          <ToggleButton
                            id="school_set_tier_platinum_btn"
                            value={3}
                          >
                            Platinum
                          </ToggleButton>
                        </ToggleButtonGroup>
                      }
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                  <small>School agreement</small>
                  <DropFiles setterFunction={setFieldValue} field={"school_agreement"} value={values.school_agreement}/>
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                id="cancel_school_btn_dialog"
                onClick={() => setDialog("close")}
                color="secondary"
              >
                Cancel
              </Button>
              <button
                type="submit"
                id="submit_school_btn_dialog"
                disabled={
                  isSubmitting ||
                  !values.name ||
                  !values.tier_id ||
                  !values.time_zone
                }
                className="btn btn-primary btn-elevate kt-login__btn-primary d-flex"
              >
                {mode === "editing" ? "Submit" : "Create"}
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    style={{ marginLeft: 10 }}
                  />
                )}
              </button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  school: store.school.school,
});

const mapDispatchToProps = (dispatch) => ({
  schoolActions: bindActionCreators(schoolDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDialog);
