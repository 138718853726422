import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Search } from '../../utils/Search';

import * as teamDUCK from "../../../redux/ducks/team.duck"
import { searchStaffMembers } from '../../crud/api/staff_members.crud';
import { saveTeam, updateTeam } from '../../crud/api/teams.crud';
import { Fragment } from 'react';
import { Delete } from '@material-ui/icons';

const TeamsDialog = ({ mode, setDialog, team, teamActions, school, staff_member_admin, is_admin }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Team" : "New Team"
  const [loading, setLoading] = useState(false);
  const [staffIndex, setStaffIndex] = useState(0);
  
  const create = async (values) => {
    const newTeam = {
      name: values.name,
      staff: values.staff?.map(({ description, staff_member_id })=> ({
        description,
        staff_member_id: staff_member_id.id,
      })),
      school_id: school.id,
    }
    try {
      await saveTeam(teamActions.updateTeams, newTeam)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {

    const newTeam = {
      name: values.name,
      staff: values.staff?.map(({ description, staff_member_id })=> ({
        description,
        staff_member_id: staff_member_id.id,
      })),
      school_id: school.id,
    };
    
    try {
      await updateTeam(teamActions.updateTeams, newTeam, team.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const addAditionalStaff = (setFieldValue, values) => {
    setFieldValue('staff', [
      ...values.staff,
      { description: '', staff_member_id: null }
    ])
  };

  const handleStaff = (setFieldValue, values, value, name, index) => {
    // If is already selected then does nothing
    if(name === "staff_member_id" && values.staff?.find(({ staff_member_id }) => staff_member_id?.id === value?.id)) return;
    let newStaff = [...values.staff];
    newStaff[index][name] = value;
    console.log('setFieldValue');
    setFieldValue('staff', newStaff);
  };

  const handleDeleteStaffMember = (e, index, values, setFieldValue) => {
    e.preventDefault();
    const newStaff = values.staff?.filter((a, idx) => index !== idx);
    setFieldValue('staff', newStaff);
  };

  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="team_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...team
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'        
          //if (!values.staff_member) errors.staff_member= 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            team.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent style={{ maxHeight: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={!(staff_member_admin || is_admin)}
                    required
                    fullWidth
                    variant="outlined"
                    id="team_name_input_dialog"
                    label="Team"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                {values?.staff?.map(({ description, staff_member_id }, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={5}>
                      <Search
                        disabled={!(staff_member_admin || is_admin)}
                        setterFunction={(field, newValue) => {
                          handleStaff(setFieldValue, values, newValue, field, index)
                          setStaffIndex(index)
                        }}
                        field="staff_member_id"
                        label="Staff"
                        value={staff_member_id}
                        // error={touched.staff[index].staff_member && errors.staff[index].staff_member_id}
                        searchFunction={searchStaffMembers}
                        slug={school && school !== null && school.slug ? school.slug : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!(staff_member_admin || is_admin)}
                        required
                        fullWidth
                        variant="outlined"
                        id="team_description_input_dialog"
                        label="Short description"
                        name="description"
                        value={description}
                        onChange={(e) => handleStaff(setFieldValue, values, e.target.value, e.target.name, index)}
                        onBlur={handleBlur}
                        onFocus={() => setStaffIndex(index)}
                        helperText={touched.name && errors.description}
                        error={Boolean(touched.name && errors.staff?.length && errors.staff[index].name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1} className="d-flex align-items-center">
                      <Tooltip title="Delete Team" placement="top">
                        <button 
                          id="team_delete_team_input_dialog" 
                          className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                          onClick={(e) => handleDeleteStaffMember(e, index, values, setFieldValue)}
                        >
                          <Delete />
                        </button>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                ))}
               
                {(staff_member_admin || is_admin) && <Grid item xs={12} sm={6}>
                  <Button onClick={() => addAditionalStaff(setFieldValue, values)} color="default">
                    Add Staff
                  </Button>
                </Grid>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={(staff_member_admin || is_admin) ? "d-flex" : 'd-flex justify-content-end w-100'} >
              {(staff_member_admin || is_admin) ? <> <Button onClick={() => setDialog("close")} color="secondary">
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    disabled={isSubmitting || !values.name || values.staff?.find(({ description, staff_member_id }) => description === "" || !staff_member_id) ||  (!school || school === null)}
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                    {mode === "editing" ? "Save" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                </> : (<button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                      Close
                  </button>)}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  team: store.team.team,
  school: store.school.selected_school,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
})

const mapDispatchToProps = (dispatch) => ({
  teamActions: bindActionCreators(teamDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDialog)
