import store from '../../../redux/store';
import notice from '../../utils/notice';
/* eslint-disable no-throw-literal */
export function getToken(){
    return store.getState().auth.authToken
}

export function getParams(method, body = null){
    return {
        method: method,
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    }
}

export function getMultipartParams(method, body = null){
    return {
        method: method,
        body: body,
        headers: {
            'Authorization': getToken()
        }
    }
}

export const handleFormData = data => {
    const formData = new FormData()
  
    for(const name in data) {
      formData.append(name, data[name]);
    }
  
    return formData
  }

export async function handleResponse(response){
    if (!response.ok) {
        if (response.status === 401){
            store.dispatch({type: '[Logout] Action'})
            notice("Session expired. Please log in again")
            throw { errors: 'Session expired' }
        } else if (response.status === 422){
            throw await response.json()
        }
        throw { errors: response.statusText }
    }
    return response.statusText === "No Content" ? {} :response.json()
}