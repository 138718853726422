import { SCHOOL_URL, FOCUS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultFocusArea = {
  name: "",
}

export const getFocusAreas = (setterFunction, slug, page = 1, query= "") => {
  if (slug) {
    return fetch(`${FOCUS_URL}?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading focus areas")
      console.error(e.errors)
      throw e
    })
  }
}

export const getFocusArea = (setterFunction, id) => {
  return fetch(`${FOCUS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading focus areas")
    console.error(e.errors)
    throw e
  })
}

export const saveFocusArea = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)
  return fetch(FOCUS_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Focus area created", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the focus area")
    console.error(e.errors)
    throw e
  })
}

export const updateFocusArea = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)
  return fetch(`${FOCUS_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Focus area updated", "success")
    return setterFunction(json)
  })
  .catch(e => {
    console.error(e)
    notice("Error saving the focus area")
    console.error(e.errors)
    throw e
  })
}