import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Calendar from './Calendar'

import * as calendarDUCK from "../../../redux/ducks/calendar.duck"
import { getAppointments } from '../../crud/api/calendar.crud';

class CalendarWrapper extends Component {

  componentDidMount() {
    const { calendarActions, user } = this.props

    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let start = new Date(y, m, 1);
    let end = new Date(y, m + 1, 0, 23, 59);

    getAppointments(calendarActions.setAppointments, user.id, start, end)
  }
  
  render() {
    return (
      <>
       <Calendar /> 
      </>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
  calendarActions: bindActionCreators(calendarDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarWrapper)