import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultAlumni } from '../../app/crud/api/alumni.crud';

export const actionType = {
  GetAlumnis: '[ALUMNI] GET_ALUMNIS',
  GetAlumni: '[ALUMNI] GET_ALUMNI',
  UpdateAlumni: '[ALUMNI] UPDATE_ALUMNI',
  DeleteAlumni: '[ALUMNI] DELETE_ALUMNI',
  LoadAlumniCsv: '[ALUMNI] LOAD_ALUMNI_CSV',
  SelectAlumni: '[ALUMNI] SELECT_ALUMNI'
}

const initialState = {
  alumnis: { data: [], isFetched: false },
  alumni: defaultAlumni,
  selected_alumni: null
}

export const reducer = persistReducer(
  { storage, key: 'alumni', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetAlumnis: {
        return { ...state, alumnis: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateAlumni: {
        
        let newData = state.alumnis.data
        const index = state.alumnis.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.alumnis.data]
        } else {
          newData = state.alumnis.data
          newData[index] = action.payload
        }

        return { ...state, alumnis: { ...state.alumnis, data: newData } }
      }

      case actionType.DeleteAlumni: {
        let newData = state.alumnis.data
        const index = state.alumnis.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.alumnis.data]
        } else {
          newData = state.alumnis.data
          newData.splice(index, 1)
        }

        return { ...state, alumnis: { ...state.alumnis, data: newData } }
      }
      
      case actionType.GetAlumni: {
        
        return { ...state, alumni: action.payload }
      }

      case actionType.LoadAlumniCsv: {
        let newData =  action.payload
        
        return { ...state, alumnis: { ...state.alumnis, data: [...state.alumnis.data, ...newData] } }
      }

      case actionType.SelectAlumni: {

        return { ...state, selected_alumni: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setAlumnis: data => ({ type: actionType.GetAlumnis, payload: data }),
  setAlumni: data => ({ type: actionType.GetAlumni, payload: data }),
  updateAlumnis: data => ({ type: actionType.UpdateAlumni, payload: data }),
  deleteAlumnis: data => ({ type: actionType.DeleteAlumni, payload: data }),
  loadAlumniCsv: data => ({ type: actionType.LoadAlumniCsv, payload: data }),
  setSelectedAlumni: state => ({ type: actionType.SelectAlumni, payload: state })
}