import React, { useEffect, useState } from 'react'
import { Paper, MuiThemeProvider, Button, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';

import FilterDataTable from '../../../utils/FilterDataTable';
import { tableMuiTheme } from '../../table/table_styles';
import SportsDialog from './SportsDialog';

import { getSports, defaultSport, getSport } from '../../../crud/api/sports.crud';
import { setLoader } from '../../../../redux/ducks/load.duck';
import * as sportDUCK from "../../../../redux/ducks/sport.duck"

const Sports = ({ sports, school, settingLoader, sportActions }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1) getSports(sportActions.setSports, school.slug, 1, query)

      else getSports(sportActions.setSports, school.slug)
    }
    
  }, [query, school, sportActions.setSports])

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'color',
      label: 'Color',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div style={{ background: value, height: 20, width: 40, borderRadius: 20 }}/>
        )
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: sports.rowsPerPage,
    page: sports.page,
    count: sports.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "grade.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: (data, meta, col) => {
      const { target } = col
      if (target.name !== "status") openDialog('editing', data[0])
    },
    onChangePage: (mPage) => {
      settingLoader(true)
      getSports(sportActions.setSports, school.slug, mPage +1, query)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
      case "create":
        settingLoader(true)
        sportActions.setSport(defaultSport)
        setDialog(mode)
        break;
      case "editing":
        settingLoader(true)
        getSport(sportActions.setSport, id)
        .then(() => setDialog(mode))
        break;
      case "close":
        setDialog(mode)
        setTimeout(() => {
          sportActions.setSport(defaultSport)
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_sport_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New sport</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            title="Sport"
            columns={columns}
            data={sports.data.map(sp => sp.sport)}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <SportsDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = store => ({
  sports: store.sport.sports,
  school: store.school.selected_school,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  sportActions: bindActionCreators(sportDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sports)