/* eslint-disable no-throw-literal */
import { SCHOOL_URL, STAFF_MEMBERS_URL, ANNOUNCEMENTS_URL, ANNOUNCEMENTS_URL_v2, UNIVERSITY_HUB_URL, ANNOUNCEMENTS_URL_v2_BASE } from "../helpers/routes"
import { handleResponse, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultAnnouncement = {
  title: "",
  description: "",
  content_type: "",
  content: "",
}

export const getAnnouncements = (setterFunction, slug, id, page = 1, query = "") => {
  if (slug) {
    return fetch(`${UNIVERSITY_HUB_URL}/${slug}/announcements?user_id=${id}&page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading the announcements")
      console.error(e.errors)
    })
  }
}

export const getAnnouncementsStaff = (setterFunction, id, page = 1, query= "") => {
  if (id) {
    return fetch(`${UNIVERSITY_HUB_URL}/staff?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading the announcements")
      console.error(e.errors)
    })
  }
}

export const getAnnouncement = (setterFunction, id) => {
  return fetch(`${UNIVERSITY_HUB_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching the announcement")
    console.error(e.errors)
    throw e
  })
}

export const saveAnnouncement = (setterFunction, data) => {
  const formData  = new FormData();

  for(const name in data) {
    if(name === 'teams_id') {
      if (typeof data['teams_id'] !== 'undefined') {
        formData.append(name, data[name].toString());
      } else {
        formData.append('teams_id', '');
      }
    } else if(name === 'students_id') {
      if (typeof data['students_id'] !== 'undefined') {
        formData.append(name, data[name].toString());
      } else {
        formData.append('students_id', '');
      }
    } else if (name === 'focus_areas') {
      if (typeof data['focus_areas'] !== 'undefined') {
        formData.append(name, data[name].toString());
      } else {
        formData.append('focus_areas', '');
      }
    } else {
      formData.append(name, data[name]);
    }
  }

  return fetch(ANNOUNCEMENTS_URL_v2, getMultipartParams('POST', formData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Announcement created", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the announcement")
    console.error(e.errors)
    throw e
  })
}

export const updateAnnouncement = (setterFunction, data, id) => {
  const formData  = new FormData();
  for(const name in data) {
    formData.append(name, data[name]);
  }
  for(const name in data) {
    if(name === 'teams_id') {
      if (typeof data['teams_id'] !== 'undefined') {
        formData.append(name, data[name].toString());
      } else {
        formData.append('teams_id', '');
      }
    } else if(name === 'students_id') {
      if (typeof data['students_id'] !== 'undefined') {
        formData.append(name, data[name].toString());
      } else {
        formData.append('students_id', '');
      }
    } else {
      formData.append(name, data[name]);
    }
  }

  return fetch(`${ANNOUNCEMENTS_URL_v2_BASE}/update?id=${id}`, getMultipartParams('PUT', formData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json && json.id) {
      notice("Announcement created", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the announcement")
      throw {error: json}
    }
  })
  .catch(e => {
    notice("Error saving the announcement")
    console.error(e.errors)
    throw e
  })
}

export const deleteAnnouncement = (setterFunction, id) => {
  return fetch(`${ANNOUNCEMENTS_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Announcement deleted", "success")
    return setterFunction({id: id})
  })
  .catch(e => {
    notice("Error deleting announcement")
    console.error(e.errors)
    throw e
  })
}