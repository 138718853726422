import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultContent } from '../../app/crud/api/content.crud';

export const actionType = {
  GetContents: '[CONTENT] GET_CONTENTS',
  GetContent: '[CONTENT] GET_CONTENT',
  UpdateContent: '[CONTENT] UPDATE_CONTENT',
  LoadContentCsv: '[CONTENT] LOAD_CONTENT_CSV',
  SelectContent: '[CONTENT] SELECT_CONTENT'
}

const initialState = {
  contents: { data: [], isFetched: false },
  content: defaultContent,
  selected_content: null
}

export const reducer = persistReducer(
  { storage, key: 'contents_', withlist: [], blacklist: ['content'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetContents: {
        return { 
          ...state, 
          contents: { 
            data: action.payload.items, 
            count: action.payload.total, 
            page: action.payload.page, 
            pages: action.payload.pages, 
            rowsPerPage: action.payload.per_page, 
            isFetched: true
          } 
        }
      }

      case actionType.UpdateContent: {
        let newData = state.contents.data
        const index = state.contents.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.contents.data]
        } else {
          newData = state.contents.data
          newData[index] = action.payload
        }
 
        return { ...state, contents: { ...state.contents, data: newData } }
      }
      
      case actionType.GetContent: {
        return { 
          ...state, 
          content: { 
            ...action.payload, 
            content_type: 
            action.payload.file_type, 
            content: action.payload.resource_url,
            categories_id: action.payload.categories?.map(({ id }) => id),
            focus_areas_ids: action.payload.focus_areas?.map(({ id }) => id),
            contributing_factors_id: action.payload.contributing_factors?.map(({ id }) => id),
            moods_id: action.payload.moods?.map(({ id }) => id),
            author_name: action.payload.author?.author,
            name: action.payload.title,
            description: action.payload.author?.description,
          },  
        }
      }

      case actionType.LoadContentCsv: {
        let newData =  action.payload
        
        return { ...state, contents: { ...state.contents, data: [...state.contents.data, ...newData] } }
      }

      case actionType.SelectContent: {

        return { ...state, selected_content: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setContents: data => ({ type: actionType.GetContents, payload: data }),
  setContent: data => ({ type: actionType.GetContent, payload: data }),
  updateContents: data => ({ type: actionType.UpdateContent, payload: data }),
  loadContentCsv: data => ({ type: actionType.LoadContentCsv, payload: data }),
  setSelectedContent: state => ({ type: actionType.SelectContent, payload: state })
}