/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, MuiThemeProvider, Paper, Select, Tooltip, useMediaQuery } from '@material-ui/core';
import { Add, Visibility } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { actionTableMuiTheme } from '../table/table_styles';

import * as dailyPicksDUCK from "../../../redux/ducks/daily_picks.duck"
import * as goalTypesDUCK from "../../../redux/ducks/goal_types.duck"
import { setLoader } from '../../../redux/ducks/load.duck';

import { defaultDailyPick, getDailyPick, getDailyPicks } from '../../crud/api/daily_picks.crud';

import DailyPicksDialog from './DailyPicksDialog';
import { getGoalTypes } from '../../crud/api/goal_types.crud';

const DailyPicks = ({ daily_picks, dpActions, settingLoader, goal_id, setGoalId }) => {
  const location = useLocation()
  const zone_id = location.pathname.includes("mind") ? 1 : location.pathname.includes("body") ? 2 : 3
  const zone = location.pathname.includes("mind") ? "mind" : location.pathname.includes("body") ? "body" : "life"
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [htmlContent, setHtmlContent] = useState("")
  const [dialog, setDialog] = useState("close")
  const [goals, setGoals] = useState([])

  useEffect(() => {
    getGoalTypes(setGoals, zone)
    .then(() => setGoalId(""))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone])

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "content",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => value && value.name && (
          <>{value.name}</>
        )
      }
    },
    {
      name: "content",
      label: "Content Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          let src

          if (value && value.content_type && value.content_type === "audio") src = `${toAbsoluteUrl("/media/assets/audio-icon.png")}`
          if (value && value.content_type && value.content_type === "html") src = `${toAbsoluteUrl("/media/assets/html5-icon.png")}`
          if (value && value.content_type && value.content_type === "link") src = `${toAbsoluteUrl("/media/assets/link-icon.png")}`
          if (value && value.content_type && value.content_type === "video") src = `${toAbsoluteUrl("/media/assets/video-icon.png")}`
          if (value && value.content_type && value.content_type === "pdf") src = `${toAbsoluteUrl("/media/assets/pdf-icon.png")}`

          return value && value.content_type && (
            <img width="40px" height="40px" src={src} alt="content_type" style={{ cursor: "pointer" }}/>
          )
        }
      }
    },
    {
      name: "content",
      label: "Sponsor",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => value && value.author && (
          <>{value.author}</>
        )
      }
    },
    {
      name: "content",
      label: "Author",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => value && value.author_name && (
          <>{value.author_name}</>
        )
      }
    },
    {
      name: 'content',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta, updater) => {
          
          return (
          <div className="d-flex">
            <Tooltip title="Edit Daily Pick" placement="top">
              <a id={`edit_daily_pick_btn_${meta.rowData[0]}`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog("editing", meta.rowData[0])}>
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>
            <Tooltip title="View Daily Pick" placement="top">
            {value ? value.content_type === "html" ?
              <a id={`view_daily_pick_content_btn_${meta.rowData[0]}`} name="content" className="btn btn-light btn-hover-success text-success btn-sm mx-3" onClick={() => setHtmlContent(value.content)}>
                <Visibility fontSize="small" />
              </a>
             : 
              <a id={`view_daily_pick_content_btn_${meta.rowData[0]}`} href={value.content} target="_blamk" className="btn btn-light btn-hover-success text-success btn-sm mx-3" name="content">
                <Visibility fontSize="small" />
              </a>
              :
              <button id={`view_daily_pick_content_btn_${meta.rowData[0]}`} className="btn btn-light btn-hover-success text-success btn-sm mx-3" disabled>
                <Visibility fontSize="small" />
              </button>
            }
            </Tooltip>
          </div>
        )}
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: daily_picks.rowsPerPage,
    page: daily_picks.page,
    count: daily_picks.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "daily_picks.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: mPage => {
      getDailyPicks(dpActions.setDailyPicks, zone_id, goal_id, mPage +1)
      settingLoader(true)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          settingLoader(true)
          setDialog(mode)
          dpActions.setDailyPick(defaultDailyPick)
          break;
        case "editing":
          settingLoader(true)
          getDailyPick(dpActions.setDailyPick, id)
          .then(() => setDialog(mode))
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            dpActions.setDailyPick(defaultDailyPick)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  const HtmlDialogContent = () => (
    <Dialog open={Boolean(htmlContent)} onClose={() => setHtmlContent("")} fullWidth maxWidth="md" id="daily_pick_content_dialog">
      <DialogTitle>Content</DialogTitle>
      <DialogContent>
        <span dangerouslySetInnerHTML={{__html: htmlContent}} />
      </DialogContent>
    </Dialog>
  )

  return (
    <>
      <div className="d-flex justify-content-between align-items-end pb-4 px-5">
        <div style={{ width: 200 }}>
          <h6>Select a goal</h6>
          <Paper>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                fullWidth
                displayEmpty
                id="daily_pick_zone_selector"
                name="zone"
                variant="outlined"
                value={goal_id}
                onChange={e => {settingLoader(true);setGoalId(e.target.value)}}
              >
                <MenuItem value="">Default</MenuItem>
                {goals.map(goal => (
                  <MenuItem key={goal.id} value={goal.id}>{goal.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </Paper>
        </div>
        <div>
          <Button id="create_daily_pick_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New daily pick</Button>
        </div>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title="Admin"
            columns={columns}
            data={daily_picks.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <HtmlDialogContent />
      <DailyPicksDialog mode={dialog} setDialog={openDialog} />
    </>
  )
}

const mapStateToProps = store => ({
  daily_picks: store.dp.daily_picks,
  goal_id: store.goal_t.selected_goal_type,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  dpActions: bindActionCreators(dailyPicksDUCK.actions, dispatch),
  setGoalId: bindActionCreators(goalTypesDUCK.actions.setSelectedGoalType, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyPicks)
