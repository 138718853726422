import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";

import * as pollDUCK from "../../../redux/ducks/poll.duck";
import {
  formatYYYYMMDDDate,
  savePoll,
  updatePoll,
} from "../../crud/api/polls.crud";
import { Autocomplete } from "@material-ui/lab";
import { TEAMS_URL, SCHOOL_URL } from "../../crud/helpers/routes";
import {
  getMultipartParams,
  handleResponse,
} from "../../crud/helpers/validate";
import { Delete } from "@material-ui/icons";
import { Fragment } from "react";
import { DatePicker } from "@material-ui/pickers";

const PollsDialog = ({
  mode,
  setDialog,
  poll,
  pollActions,
  school,
  staff_member_admin,
  is_admin,
}) => {
  const open = mode === "editing" || mode === "create";
  const title = mode === "editing" ? "Edit Poll" : "New Poll";
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  const getTeams = (setterFunction, slug) => {
    return fetch(`${TEAMS_URL}?per_page=100`, getMultipartParams("GET"))
      .then((res) => handleResponse(res))
      .then((json) => {
        setterFunction([
          {
            id: -1,
            name: "All tems in this school",
            description: "All teams in this school",
            staff_member: -1,
          },
          ...json.items,
        ]);
      })
      .catch((e) => {
        console.error(e.errors);
        return e;
      });
  };

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null)
      getTeams(setTeams, school.slug);
  }, [school]);

  const create = async (values) => {
    const newPoll = {
      poll: {
        name: values.name,
        close_date: formatYYYYMMDDDate(values.close_date),
        question_text: values.question_text,
        all_teams: values.all_teams,
      },
      options: values.options,
      teams: values.all_teams ? [] : values.teams.map((id) => ({ id })),
      slug: school.slug,
    };

    try {
      await savePoll(pollActions.updatePolls, newPoll);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const newPoll = {
      poll: {
        name: values.name,
        close_date: formatYYYYMMDDDate(values.close_date),
        question_text: values.question_text,
        all_teams: values.all_teams,
      },
      options: values.options.filter(({ disabled }) => !disabled),
      teams: values.all_teams ? [] : values.teams.map((id) => ({ id })),
      slug: school.slug,
    };

    try {
      await updatePoll(pollActions.updatePolls, newPoll, poll.id);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const removeOption = (e, index, setFieldValue, values) => {
    e.preventDefault();
    const newOptions = values.options?.filter((a, idx) => idx !== index);
    setFieldValue("options", newOptions);
  };

  const addOption = (setFieldValue, values) => {
    const newOptions = [...values.options, ""];
    setFieldValue("options", newOptions);
  };

  const handleChangeAllTeams = (v, setFieldValue) => {
    setFieldValue("all_teams", v);
    if (v) {
      setFieldValue("teams", []);
    }
  };
  return (
    <Dialog
      size={"md"}
      open={open}
      onClose={() => setDialog("close")}
      id="poll_dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...poll,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) errors.name = "Required field";
          //if (!values.staff_member) errors.staff_member= 'Required field'

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          poll.id ? update(values) : create(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={{ maxHeight: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="poll_name_input_dialog"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    id="calendar_close_date_input_dialog"
                    inputVariant="outlined"
                    label="Date"
                    name="close_date"
                    value={values.close_date}
                    onChange={(value) => setFieldValue("close_date", value)}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="poll_question_text_input_dialog"
                    label="Question Text"
                    name="question_text"
                    value={values.question_text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.question_text && errors.question_text}
                    error={Boolean(
                      touched.question_text && errors.question_text
                    )}
                  />
                </Grid>
                {values.options?.map(({ value, disabled }, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={disabled}
                        required
                        fullWidth
                        variant="outlined"
                        id={`poll_option[${index}]_dialog`}
                        label={`Option ${index + 1}`}
                        name={`options[${index}].value`}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.options && errors.options)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      className="d-flex align-items-center"
                    >
                      <Tooltip title="Remove Option" placement="top">
                        <button
                          disabled={disabled}
                          id="team_delete_team_input_dialog"
                          className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                          onClick={(e) =>
                            removeOption(e, index, setFieldValue, values)
                          }
                        >
                          <Delete />
                        </button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={7}></Grid>
                  </Fragment>
                ))}
                {
                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={() => addOption(setFieldValue, values)}
                      color="default"
                      disabled={!(staff_member_admin || is_admin)}
                    >
                      Add option
                    </Button>
                  </Grid>
                }
                <Grid item xs={12} sm={12}>
                  <Checkbox
                    id="poll_all_teams_input_dialog"
                    value={values.all_teams}
                    checked={values.all_teams}
                    onChange={(e, v) => handleChangeAllTeams(v, setFieldValue)}
                  />{" "}
                  Show poll to all teams in all schools
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="tags-teams"
                    disabled={values.all_teams}
                    fullWidth
                    multiple
                    options={teams}
                    defaultValue={mode === "editing" ? poll?.teams : []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, val) => {
                      values.teams = [];

                      val.forEach((element) => {
                        values.teams.push(element.id);
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Teams"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div
                className={
                  staff_member_admin || is_admin
                    ? "d-flex"
                    : "d-flex justify-content-end w-100"
                }
              >
                {staff_member_admin || is_admin ? (
                  <>
                    {" "}
                    <Button
                      onClick={() => setDialog("close")}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !values.name ||
                        values.staff?.find(
                          ({ description, staff_member_id }) =>
                            description === "" || !staff_member_id
                        ) ||
                        !school ||
                        school === null
                      }
                      className="btn btn-primary btn-elevate kt-login__btn-primary d-flex"
                    >
                      {mode === "editing" ? "Save" : "Create"}
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={18}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3"
                  >
                    Close
                  </button>
                )}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  poll: store.poll.poll,
  school: store.school.selected_school,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
});

const mapDispatchToProps = (dispatch) => ({
  pollActions: bindActionCreators(pollDUCK.actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PollsDialog);
