import { POLLS_URL } from "../helpers/routes";
import {
  handleResponse,
  getMultipartParams,
  getParams,
} from "../helpers/validate";
import notice from "../../utils/notice";

// export const formatYYYYMMDDDate = dateValue => {
//   const offset = dateValue.getTimezoneOffset()
//   dateValue = new Date(dateValue.getTime() - (offset*60*1000))
//   return dateValue.toISOString().split('T')[0]
// }

export const formatYYYYMMDDDate = (dateValue) => {
  const date = new Date(dateValue);
  return date.toISOString().split("T")[0];
};

export const defaultPoll = {
  name: "",
  close_date: "",
  question_text: "",
  options: [{ value: "", disabled: false }],
  teams: [],
  all_teams: false,
};

export const getPolls = (setterFunction, page = 1, query = "") => {
  return fetch(
    `${POLLS_URL}?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the polls");
      console.error(e.errors);
      throw e;
    });
};

export const getPoll = (setterFunction, id) => {
  return fetch(`${POLLS_URL}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the poll");
      console.error(e.errors);
      throw e;
    });
};

export const savePoll = (setterFunction, data) => {
  return fetch(POLLS_URL, getParams("POST", JSON.stringify(data)))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Poll created", "success");
      setterFunction(json);
    })
    .catch((e) => {
      notice("Error saving the poll");
      console.error(e.errors);
      throw e;
    });
};

export const updatePoll = (setterFunction, data, id) => {
  return fetch(
    `${POLLS_URL}/update?id=${id}`,
    getParams("PUT", JSON.stringify(data))
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json.id) {
        setterFunction(json);
        notice("Poll updated", "success");
      } else {
        notice("Eror updating the poll");
        console.error(json);
      }
    })
    .catch((e) => {
      notice("Error updating the poll");
      console.error(e.errors);
    });
};
