/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Paper, createMuiTheme, MuiThemeProvider, Grid, CardMedia, CardContent, Typography, Card, ButtonGroup, Button, useMediaQuery, CardActions, Tooltip } from '@material-ui/core'
import { Delete, Add, School, Phone, Publish, Visibility } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import moment from "moment"

import PasswordReset from '../PasswordReset';
import StudentDialog from './StudentDialog';
import FilterDataTable from '../../../utils/FilterDataTable';

import { defaultStudent, getStudents, getStudent, deleteStudent, notifyStudent, studentBulk } from '../../../crud/api/students.crud';
import * as studentDUCK from "../../../../redux/ducks/students.duck"
import { setLoader } from '../../../../redux/ducks/load.duck';
import { setAlert } from '../../../../redux/ducks/alert.duck';
import notice from '../../../utils/notice';
import { BulkModal } from '../../../utils/BulkModal';

const Student = ({ students, school, studentActions, settingLoader, setAlert, staff_member_admin, is_admin }) => {
  const [isGrid, setIsGrid] = useState(false)
  const [dialog, setDialog] = useState("close")
  const [email, setEmail] = useState("")
  const [query, setQuery] = useState("")
  const [bulk, setBulk] = useState(null)
  const [bulkModal, setBulkModal] = useState(false)
  const [bulkLoading, setBulkLoading] = useState(false)
  const isDesktop = useMediaQuery("(min-width: 992px)")
  
  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1) getStudents(studentActions.setStudents, school.slug, 1, query)
      
      else getStudents(studentActions.setStudents, school.slug, 1)
    }
  }, [query, school, studentActions.setStudents])
  

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true)
        studentActions.setStudent(defaultStudent)
        setDialog(mode)
        break;
      case "editing":
        settingLoader(true)
        getStudent(studentActions.setStudent, id)
        .then(() => setDialog(mode))
        break;
      case "close":
        setDialog(mode)
        setTimeout(() => {
          studentActions.setStudent(defaultStudent)
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode")
    }
  }

  const deleteStudents = (id) => {
    setAlert({
      title: "Delete Student",
      message: "Are you sure to permanently delete this student?",
      btn_msg: "Delete Student",
      action: () => deleteStudent(studentActions.deleteStudents, id)
    })
  }

  const notifyStudents = (email) => {
    setAlert({
      title: "Resend student code",
      message: "Are you sure to resend student code?",
      btn_msg: "Resend student code",
      action: () => notifyStudent(email)
    })
  }

  const createStudentBulk = (values) => {
    setBulkLoading(true)
    if (!school?.slug) {
      setBulkLoading(false)
      notice("Please select a school")
    } else if (!bulk){
      setBulkLoading(false)
      notice("Please add a csv file")
    } else studentBulk(school.slug, bulk, values.team_id)
      .then(() => {
        setBulkModal(false);
        setBulk(null)
        setTimeout(() => 
          getStudents(studentActions.setStudents, school.slug, 1, query)
        , 2000);
      }).finally(() => setBulkLoading(false))
  }

  const columns = [
    {
      name: 'user',
      label: 'Picture',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && (value.avatar || value.avatar_thumb) && <div style={{ background: `url(${value.avatar_tumb ? value.avatar_tumb : value.avatar }) center center / cover no-repeat`, height: 40, width: 40, borderRadius: "50%", margin: "-5px 0" }}/>
        )
      }
    },
    {
      name: 'user',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value.first_name} {value.last_name}</>
        )
      }
    },
    {
      name: 'user',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value.email}</>
        )
      }
    },
    {
      name: 'created_at',
      label: 'Date Registered',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>
          {moment(value).format("MMM DD YYYY")}
          </>
        )
      }
    },
    {
      name: 'team',
      label: 'Team',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value && value !== null ? value.name : ""}</>
        )
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => value && (
          <div className="d-flex">
            <Tooltip title="Edit Student" placement="top">
              <a id={`edit_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog('editing', value)}>
                {(staff_member_admin || is_admin) ? <i className="fas fa-edit text-primary" /> : <Visibility/>}
              </a>
            </Tooltip>
            {(staff_member_admin || is_admin) && <Tooltip title="Delete Student" placement="top">
              <a id={`delete_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3" onClick={() => deleteStudents(value)}>
                <Delete />
              </a>
            </Tooltip>}
            {/* <Tooltip title="Edit Password" placement="top">
              <a id={`passw_change_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => setEmail(meta.rowData[0].email)}>
                <VpnKey />
              </a>
            </Tooltip>
            <Tooltip title="Resend student code" placement="top">
              <a id={`notify_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-info text-info mx-3" onClick={() => notifyStudents(meta.rowData[0].email)}>
                <Mail />
              </a>
            </Tooltip> */}
          </div>
        )
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: students.rowsPerPage,
    page: students.page,
    count: students.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "students.csv",
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayRowsOnly: true }
    },
    onChangePage: (mPage) => {
      getStudents(studentActions.setStudents, school.slug, mPage +1, query)
      settingLoader(true)
    }
  };

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: "4px 10px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          // eslint-disable-next-line no-useless-computed-key
          ['@media(min-width: 960px)']: {
            maxWidth: 200,
          }
        }
      },
      MUIDataTableToolbar: {
        root: {
          background: "linear-gradient(125deg, rgba(17, 151, 192, 1) 25%, rgba(4, 7, 25, 1) 100%)",
          minHeight: "50px",
          height: "50px",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
          color: 'white'
        },
        icon: {
          color: 'white'
        },
        titleRoot: {
          display: 'none'
        },
        left: {
          marginLeft: 80
        }
      },
      MUIDataTable: {
        root: {
          display: !isGrid ? "block" : "none"
        },
      },
      MuiTable: {
        root: {
          display: !isGrid ? "table" : "none"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          zIndex: 90
        }
      }
    }
  })

  const cardView = () => (
    <Grid container spacing={2} style={{ padding: "25px 10px" }}>
      {students.data.map(stu => {
        return (
          <Grid item key={stu.id} xs={12} sm={6} md={3}>
            <Card>
              <CardMedia
                style={{ height: 125 }}
                image={stu.user.avatar_tumb ? stu.user.avatar_tumb : stu.user.avatar}
                title="user"
              />
              <CardContent>
                <Typography variant="h5">{stu.user.first_name} <span style={{ fontSize: "1rem" }}>{stu.user.last_name}</span></Typography>
                <Typography>Team: {stu.team && stu.team !== null ? stu.team.name : ""}</Typography>
                <Typography>Email: {stu.user.email}</Typography>
                <Typography variant="body2" color="textSecondary"><School className="mr-1" /> {stu.classification}</Typography>
                <Typography variant="body2" color="textSecondary"><Phone className="mr-1" /> {stu.user && stu.user.phone_number}</Typography>
              </CardContent>
              <CardActions disableSpacing className="justify-content-end">
                <Tooltip title="Edit Student" placement="top">
                  <a id={`edit_student_btn_${stu.id}_card`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog('editing', stu.id)}>
                    <i className="fas fa-edit text-primary" />
                  </a>
                </Tooltip>
                <Tooltip title="Delete Student" placement="top">
                  <a id={`delete_student_btn_${stu.id}_card`} className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3" onClick={() => deleteStudents(stu.id)}>
                    <Delete />
                  </a>
                </Tooltip>
                {/* <Tooltip title="Edit Student Password" placement="top">
                  <a id={`passw_change_student_btn_${stu.id}_card`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => setEmail(stu.user.email)}>
                    <VpnKey />
                  </a>
                </Tooltip> */}
              </CardActions>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
  return (
    <>
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <div>
          {(staff_member_admin || is_admin) && <>
            <Button id="create_bulk_student_btn" className="mr-3" variant="contained" color="primary" onClick={() => setBulkModal(true)} startIcon={<Publish />}>
              Bulk Upload
            </Button>
            <Button id="create_student_btn" variant="contained" color="secondary" onClick={() => openDialog("create")} startIcon={<Add />}>
              New Student
            </Button>
          </>}
        </div>
      </div>
      <Paper>
        <MuiThemeProvider theme={getMuiTheme()}>
          <ButtonGroup
            variant="text"
            style={{
              position: "absolute",
              zIndex: 1,
              margin: 10
            }}>
            {/* <Button id="student_list_view_btn" style={{ color: "white", border: "none" }} onClick={() => setIsGrid(false)}>
              <Reorder />
            </Button>
            <Button id="student_grid_view_btn" style={{ color: "white" }} onClick={() => setIsGrid(true)}>
              <Apps />
            </Button> */}
          </ButtonGroup>
          <MUIDataTable
            title="Admin"
            columns={columns}
            data={students.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      {isGrid && cardView()}
    </div>
    <StudentDialog mode={dialog} setDialog={openDialog} />
    <PasswordReset email={email} setEmail={setEmail} />
    <BulkModal open={bulkModal} setOpen={setBulkModal} bulk={bulk} setBulk={setBulk} school={school} loading={bulkLoading} bulkRequest={createStudentBulk} setLoading={setBulkLoading}/>
    </>
  )
}

const mapStateToProps = store => {
  return ({
  school: store.school.selected_school,
  students: store.student.students,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  studentActions: bindActionCreators(studentDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Student)
