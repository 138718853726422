export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_v2 = process.env.REACT_APP_API_BASE_URL_v2;

export const CONFERENCE_URL = `${BASE_URL}/conferences`;
export const SCHOOL_URL = `${BASE_URL}/schools`;
export const SCHOOL_URL_V2 = `${BASE_URL_v2}/schools`;
export const TEAMS_URL = `${BASE_URL}/teams`;
export const STAFF_MEMBERS_URL = `${BASE_URL}/staff_members`;
export const STAFF_TYPES_URL = `${BASE_URL}/staff_types`;
export const ANNOUNCEMENTS_URL = `${BASE_URL}/announcements`;
export const ANNOUNCEMENTS_URL_v2_BASE = `${BASE_URL_v2}/university_hub`;
export const ANNOUNCEMENTS_URL_v2 = `${BASE_URL_v2}/university_hub/create`;
export const USERS_URL = `${BASE_URL}/users`;
export const GOALS_URL = `${BASE_URL}/goals`;
export const FEELINGS_URL = `${BASE_URL}/feelings`;
export const STUDENTS_URL = `${BASE_URL}/students`;
export const SPORTS_URL = `${BASE_URL}/sports`;
export const APPOINTMENT_URL = `${BASE_URL}/appointments`;
export const EVENT_URL = `${BASE_URL}/events`;
export const ALUMNI_URL = `${BASE_URL}/alumni`;
export const DAILY_PICKS_URL = `${BASE_URL}/content_rules`;
export const CONTENT_AUTHORS_URL = `${BASE_URL}/content_authors`;
export const CONTENTS_URL = `${BASE_URL}/contents`;
export const CONTENTS_URL_v2 = `${BASE_URL_v2}/library`;
export const CREATE_CONTENTS_URL = `${BASE_URL_v2}/library/create-content`;
export const MOODS_URL = `${BASE_URL}/moods`;
export const UNIVERSITY_HUB_URL = `${BASE_URL_v2}/university_hub`;
export const FOCUS_URL = `${BASE_URL_v2}/focus-areas`;
export const LIBRARY_URL = `${BASE_URL_v2}/library`;
export const DASHBOARD_URL = `${BASE_URL_v2}/dashboard`;
export const POLLS_URL = `${BASE_URL_v2}/polls`;
