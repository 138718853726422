import { Grid } from "@material-ui/core";
import { makeStyles, MenuItem, useTheme, darken } from "@material-ui/core";
import React from "react";
import { useIcons } from "../../../hooks/useIcons";
import DashboardTitle from "./DashboardTitle";
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: darken("#152CFF", 0.3),
    padding: "10px 16px 10px 32px",
    borderRadius: 8,
    // Applies to all texts
    "&>*": {
      "&>*": {
        lineHeight: 1,
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
  },
  percent: {
    display: "grid",
    justifyItems: "end",
  },
  menuItem: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const TeleHelpNotifications = ({
  telehelp_counter = 0,
  text,
  icon = "NotificationsIcon",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const Icon = useIcons(icon);
  return (
    <MenuItem className={classes.menuItem}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.item}
      >
        <Grid container item xs={1} sm={1} md={1} lg={1}>
          <DashboardTitle
            variant="dark.grey3"
            fontWeight={600}
            fontSize={28}
            text={telehelp_counter}
          />
        </Grid>
        <Grid container item xs={8} sm={8} md={8} lg={8}>
          <DashboardTitle
            variant="dark.grey3"
            fontWeight={500}
            fontSize={20}
            text={text}
          />
        </Grid>
        <Grid
          container
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          className={classes.percent}
        >
          {icon && <Icon fontSize="large" style={{ color: "#152CFF" }} />}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

const mapStateToProps = (store) => ({
  telehelp_counter: store.dashboard.data.dashboard_data.telehelp_counter,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeleHelpNotifications);
