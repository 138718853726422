import React from "react";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";

import * as Menu from "../../../MenuConfig"
import SchoolSelector from "./SchoolSelector"
import { MenuOption } from "./MenuOption";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Divider } from "@material-ui/core";

function AsideMenuList({ layoutProps }) {
  const { user } = useSelector(state => state.auth);
  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {(user.is_admin || (user && user && user.is_admin)) &&
            <li style={{ padding: "0 25px", marginBottom: 25 }}>
              <SchoolSelector />
            </li>
          }
          {/*begin::1 Level*/}
          {Menu.default.aside.items.map(item => {
            return ((item.admin && !user.is_admin) || (item.staff_member_admin && (!user.staff_member_admin && !user.is_admin))) ? <></> : (
              <MenuOption item={item} key={item.title} />
            );
          })}
          <Divider />
          {/*<li className="menu-item">
            <a className="menu-link menu-toggle" target="_blamk" href="http://itsthezone.zendesk.com/hc/en-us">
              <span className="svg-icon menu-icon">
                <Help />
              </span>
              <span className="menu-text">Help Center</span>
            </a>
        </li>*/}
          <li className="menu-item">
            <a className="menu-link menu-toggle" target="_blamk" href="https://docs.google.com/forms/d/e/1FAIpQLSecHYEtASE941oGMtTn2gP2TbNyJyw95IkZaVq_EDB9VXkYwg/viewform?usp=sf_link">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(`/media/svg/icons/Communication/Incoming-box.svg`)}/>
              </span>
              <span className="menu-text">Feedback</span>
            </a>
          </li>
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}

export default AsideMenuList