import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { ContentRoute } from '../../../_metronic/layout';

import ContentAuthors from './ContentAuthors/ContentAuthors';
import Conference from './Conference/Conference';
import Feelings from './Feelings/Feelings';
import Sports from './Sports/Sports';
import Staff from './Staff/Staff';
import Goals from './Goals/Goals';
import FocusAreas from './FocusAreas/FocusAreas';

class ConfigWrapper extends Component {

  render() {
    const { user } = this.props
    // only show conference and Goals when user is admin

    return (
      <Switch>
        <Redirect
          exact={true}
          from="/config"
          to="/config/staff_types"
        />
        {/* Surfaces */}
        <ContentRoute from="/config/staff_types" component={Staff} />
        <ContentRoute from="/config/emotions" component={Feelings} />
        <ContentRoute from="/config/sports" component={Sports} />
        <ContentRoute from="/config/content_authors" component={ContentAuthors} />
        {user.is_admin && <ContentRoute from="/config/conference" component={Conference} />}
        {user.is_admin && <ContentRoute from="/config/goal_types" component={Goals} />}
        {user.is_admin && <ContentRoute from="/config/focus_areas" component={FocusAreas} />}
      </Switch>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user,
})

export default connect(mapStateToProps, null)(ConfigWrapper)