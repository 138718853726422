/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Paper, MuiThemeProvider, Button, useMediaQuery, Tooltip } from '@material-ui/core'
import { Add, Delete, Visibility, VpnKey } from '@material-ui/icons';
import MUIDataTable from "mui-datatables";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from "moment"

import { actionTableMuiTheme } from "../../table/table_styles"
import StaffDialog from './StaffDialog';
import PasswordReset from '../PasswordReset';
import FilterDataTable from '../../../utils/FilterDataTable';

import { setLoader } from '../../../../redux/ducks/load.duck';
import * as staffMDUCK from "../../../../redux/ducks/staff_members.duck"
import { defaultStaffMember, getStaffMembers, getStaffMember, deleteStaffMember } from '../../../crud/api/staff_members.crud';
import { setAlert } from '../../../../redux/ducks/alert.duck';
import NotificationsIcon from '@material-ui/icons/Notifications';

const Staff = ({ settingLoader, staffMActions, staffMembers, school, setAlert, staff_member_admin, is_admin }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")
  const [email, setEmail] = useState("")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1) getStaffMembers(staffMActions.setStaffMembers, school.slug, 1, query)
      
      else getStaffMembers(staffMActions.setStaffMembers, school.slug, 1)
    }
  }, [query, school, staffMActions.setStaffMembers])

  const deleteStaff = (id) => {
    setAlert({
      title: "Delete Staff Member",
      message: "Are you sure to permanently delete this student?",
      btn_msg: "Delete Staff Member",
      action: () => deleteStaffMember(staffMActions.deleteStaffMembers, id)
    })
  }
  
  const columns = [
    {
      name: 'user',
      label: 'Picture',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && value.avatar_thumb && <div style={{ background: `url(${value.avatar_thumb}) center center / cover no-repeat`, height: 40, width: 40, borderRadius: "50%", margin: "-5px 0" }}/>
        )
      }
    },
    {
      name: 'user',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value.first_name} {value.last_name}</>
        )
      }
    },
    {
      name: 'user',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>{value.email}</>
        )
      }
    },
    // {
    //   name: 'staff_type',
    //   label: 'Staff type',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => (
    //       value && <>{value.name}</>
    //     )
    //   }
    // },
    {
      name: 'created_at',
      label: 'Date Registered',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <>
            {moment(value).format("MMM DD YYYY")}
          </>
        )
      }
    },
    {
      name: 'job_title',
      label: 'Role',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (job_title) => (
          job_title && <>{job_title}</>
        )
      }
    },
    {
      name: 'display_in_telehelp',
      label: 'Telehelp',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value && <NotificationsIcon className="w-100"/>
        )
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => value && (
          <div className="d-flex">
            <Tooltip title="Edit Staff Member" placement="top">
              <a id={`edit_staff_member_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-primary " onClick={() => openDialog('editing', value)}>
                {(staff_member_admin || is_admin) ? <i className="fas fa-edit text-primary" /> : <Visibility/>}
              </a>
            </Tooltip>
            {(staff_member_admin || is_admin) && <Tooltip title="Delete Staff Member" placement="top">
              <a id={`delete_staff_member_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3" onClick={() => deleteStaff(value)}>
                <Delete />
              </a>
            </Tooltip>}
            {/* <Tooltip title="Edit Password" placement="top">
              <a id={`passw_change_staff_member_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => {setEmail(meta.rowData[0].email)}}>
                <VpnKey />
              </a>
            </Tooltip> */}
          </div>
        )
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: staffMembers.rowsPerPage,
    page: staffMembers.page,
    count: staffMembers.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "staff_members.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: (mPage) => {
      getStaffMembers(staffMActions.setStaffMembers, school.slug, mPage +1, query)
      settingLoader(true)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          settingLoader(true)
          staffMActions.setStaffMember(defaultStaffMember)
          setDialog(mode)
          break;
        case "editing":
          settingLoader(true)
          getStaffMember(staffMActions.setStaffMember, id)
          .then(() => setDialog(mode))
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => staffMActions.setStaffMember(defaultStaffMember), 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }
  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        {(staff_member_admin || is_admin) && <Button id="create_staff_member_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}>
          <Add /> New Staff Member
        </Button>}
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title="Admin"
            columns={columns}
            data={staffMembers.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <StaffDialog mode={dialog} setDialog={openDialog}/>
      <PasswordReset email={email} setEmail={setEmail} />
    </div>
  )
}

const mapStateToProps = store => ({
  staffMembers: store.staffM.staffMembers,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  staffMActions: bindActionCreators(staffMDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Staff)
