import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, CircularProgress } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import StudentTabInfo from './StudentTabInfo'
import { StudentTabMind } from './StudentTabMind'
import { StudentTabBody } from './StudentTabBody'
import { StudentTabLife } from './StudentTabLife'

import * as studentDUCK from "../../../../redux/ducks/students.duck"
import { deleteStudent, saveStudent, updateStudent } from '../../../crud/api/students.crud'
import { setAlert } from '../../../../redux/ducks/alert.duck'
import { Delete } from '@material-ui/icons'

const StudentDialog = ({ mode, setDialog, school, student, studentActions, setAlert, staff_member_admin, is_admin }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Student" : "New Student"
  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(false);

  const close = () => {setTab(0); setDialog("close")}

  const renderComponent = (values, errors, touched, handleChange, handleBlur, setFieldValue) => {
    let component
    switch (tab) {
      case 0:
        component = <StudentTabInfo values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} setFieldValue={setFieldValue} />
        break;
      case 1:
        component = <StudentTabMind values={values} handleChange={handleChange} handleBlur={handleBlur} />
        break;
      case 2:
        component = <StudentTabBody values={values} handleChange={handleChange} handleBlur={handleBlur} />
        break;
      case 3:
        component = <StudentTabLife values={values} handleChange={handleChange} handleBlur={handleBlur} />
        break;
      default:
        component = <StudentTabInfo values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
        break;
    }
    return component
  }

  const create = async values => {
    const newStudent = {
      ...values,
      school_id: school.id
    }
    try {
      await saveStudent(studentActions.updateStudents, newStudent)
      setLoading(false);
      close()
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {
    const newStudent = {
      ...values,
      school_id: school.id,
      id: student.id,
    }
    try {
      console.log({newStudent})
      await updateStudent(studentActions.updateStudents, student.id, newStudent)
      setLoading(false);
      close()
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteStudents = (id) => {
    setAlert({
      title: "Delete Student",
      message: "Are you sure to permanently delete this student?",
      btn_msg: "Delete Student",
      action: () => deleteStudent(studentActions.deleteStudents, id).then(() => close())
    })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      id="student_dialog"
      onClose={() => close()}>
      <Formik
        initialValues={{
          first_name: student.user && student.user.first_name ? student.user.first_name : "",
          last_name: student.user && student.user.last_name ? student.user.last_name : "",
          email: student.user && student.user.email ? student.user.email : "",
          image: student.user && student.user.avatar ? student.user.avatar : null,
          phone_number: student.user && student.user.phone_number ? student.user.phone_number : "",
          team_id: student.team && student.team.id ? student.team.id : "",
          code: student.code,
          height: student.height,
          weight: student.weight,
          gender: student.gender,
          classification: student.classification,
        }}
        validate={values => {
          const expression = /\S+@\S+/
          const errors = {}
          if (!values.first_name) errors.first_name = 'Required field'
          if (!values.last_name) errors.last_name = 'Required field'
          if (!values.email) errors.email = 'Required field'
          if (!expression.test(values.email)) errors.email = 'Invalid email format'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            student.id ? update(values) : create(values)            
            setSubmitting(false)
          }, 1000)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogTitle>
              {title}
            </DialogTitle>
            <DialogContent>
              {/* {student.id && <Tabs
                value={tab}
                onChange={(e, value) => setTab(value)}
                scrollButtons="auto"
                className="aside-bkground"
                style={{ marginBottom: 20 }}
                TabIndicatorProps={{style: { backgroundColor: "white" }}}
                centered
              >
                <Tab label="Personal Info"/>
                <Tab label="Mind"/>
                <Tab label="Body"/>
                <Tab label="Life"/>
              </Tabs>} */}
              {renderComponent(values, errors, touched, handleChange, handleBlur, setFieldValue)}
            </DialogContent>
            <DialogActions className={student.id && "justify-content-between"}>
              {(student.id && (staff_member_admin || is_admin)) && <button id="delete_student_btn_dialog" type="reset" className="btn btn-danger" onClick={() => deleteStudents(student.id)}>
                <Delete /> Delete Student
              </button>}
              <div className={(staff_member_admin || is_admin) ? "d-flex" : 'd-flex justify-content-end w-100'} >
                {(staff_member_admin || is_admin) ? <>
                  <Button id="cancel_student_btn_dialog" type="reset" onClick={() => close()} color="secondary">
                      Cancel
                  </Button>
                  <button
                    type="submit"
                    id="submit_student_btn_dialog"
                    disabled={isSubmitting || !values.first_name || !values.last_name || !values.email || (!school || school === null)}
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                    {mode === "editing" ? "Submit" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                </> : 
                  (<button
                    type="button"
                    onClick={() => close()}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                      Close
                  </button>)}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => {
  return ({
    school: store.school.selected_school,
    student: store.student.student,
    staff_member_admin: store.auth.user?.staff_member_admin,
    is_admin: store.auth.user?.is_admin,
  })
}

const mapDispatchToProps = dispatch => ({
  studentActions: bindActionCreators(studentDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentDialog)
