import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultSchool } from '../../app/crud/api/schools.crud';

export const actionType = {
  GetSchools: '[SCHOOL] GET_SCHOOLS',
  GetSchool: '[SCHOOL] GET_SCHOOL',
  UpdateSchool: '[SCHOOL] UPDATE_SCHOOL',
  LoadSchoolCsv: '[SCHOOL] LOAD_SCHOOL_CSV',
  SelectSchool: '[SCHOOL] SELECT_SCHOOL'
}

const initialState = {
  schools: { data: [], isFetched: false },
  school: defaultSchool,
  selected_school: null
}

export const reducer = persistReducer(
  { storage, key: 'school', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetSchools: {
        return { ...state, schools: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateSchool: {
        let newData = state.schools.data
        const index = state.schools.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.schools.data]
        } else {
          newData = state.schools.data
          newData[index] = action.payload
        }

        return { ...state, schools: { ...state.schools, data: newData } }
      }
      
      case actionType.GetSchool: {
        
        return { ...state, school: action.payload }
      }

      case actionType.LoadSchoolCsv: {
        let newData =  action.payload
        
        return { ...state, schools: { ...state.schools, data: [...state.schools.data, ...newData] } }
      }

      case actionType.SelectSchool: {

        return { ...state, selected_school: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setSchools: data => ({ type: actionType.GetSchools, payload: data }),
  setSchool: data => ({ type: actionType.GetSchool, payload: data }),
  updateSchools: data => ({ type: actionType.UpdateSchool, payload: data }),
  loadSchoolCsv: data => ({ type: actionType.LoadSchoolCsv, payload: data }),
  setSelectedSchool: state => ({ type: actionType.SelectSchool, payload: state })
}