import { STAFF_MEMBERS_URL, SCHOOL_URL } from "../helpers/routes"
import { getMultipartParams, handleResponse } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultStaffMember = {
  status: true,
  first_name: "",
  last_name: "",
  email: "",
  staff_type: null,
  password: "",
  student_zone_ids: [],
  phone_number: "",
}

export const getStaffMembers = (setterFunction, slug, page = 1, query = "") => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/staff_members?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading staff members")
      console.error(e.errors)
      throw e
    })
  }
}

export const getStaffMember = (setterFunction, id) => {
  return fetch(`${STAFF_MEMBERS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on fetching staff member")
    console.error(e.errors)
    throw e
  })
}

export const saveStaffMembers = (setterFunction, data) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(STAFF_MEMBERS_URL, getMultipartParams('POST', formData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Staff member created", "success")
    json.id && setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the staff")
    console.error(e.errors)
    throw e
  })
}

export const updateStaffMembers = (setterFunction, data, id) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(`${STAFF_MEMBERS_URL}/update?id=${id}`, getMultipartParams('PUT', formData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Staff member updated", "success")
    json.id && setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the staff")
    console.error(e.errors)
    throw e
  })
}

export const deleteStaffMember = (setterFunction, id) => {
  return fetch(`${STAFF_MEMBERS_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Staff member deleted", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error deleting staff member")
    console.error(e.errors)
    throw e
  })
}

export const searchStaffMembers = (setterFunction, slug, query) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/staff_members/suggestions?q=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading staff members")
      console.error(e.errors)
    })
  }
}