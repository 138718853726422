import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ContentRoute } from '../../../_metronic/layout'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DailyPicks from './DailyPicks'

import { getDailyPicks } from '../../crud/api/daily_picks.crud'

import * as dailyPicksDUCK from "../../../redux/ducks/daily_picks.duck"
import * as goalTypesDUCK from "../../../redux/ducks/goal_types.duck"
import { setLoader } from '../../../redux/ducks/load.duck'

class DailyPicksWrapper extends Component {
  state = {
    zone_id: 1,
  }

  getRules() {
    const { location, dpActions, goal_id } = this.props

    let zone = location.pathname.slice(13)
    let zone_id

    switch (zone) {
      case "mind":
        zone_id = 1
        break
        
      case "body":
        zone_id = 2
        break
        
      case "life":
        zone_id = 3
        break
    
      default:
        console.log("NO ZONE SELECTED")
        break;
    }

    getDailyPicks(dpActions.setDailyPicks, zone_id, goal_id, 1)
  }
  
  render() {

    return (
      <div className="table-container">
        {this.getRules()}

        <Switch>
          <Redirect
            exact={true}
            from="/daily_picks"
            to="/daily_picks/mind"
          />
          <ContentRoute from="/daily_picks/mind" component={DailyPicks} />
          <ContentRoute from="/daily_picks/body" component={DailyPicks} />
          <ContentRoute from="/daily_picks/life" component={DailyPicks} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return ({
  goal_id: store.goal_t.selected_goal_type,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  dpActions: bindActionCreators(dailyPicksDUCK.actions, dispatch),
  setGoalId: bindActionCreators(goalTypesDUCK.actions.setSelectedGoalType, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyPicksWrapper)