import React, { useState, createRef } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, ButtonGroup, IconButton, Paper, useMediaQuery } from '@material-ui/core';
import { Add, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from "moment"

import CalendarDialog from './CalendarDialog';
import { setLoader } from '../../../redux/ducks/load.duck';
import * as calendarDUCK from "../../../redux/ducks/calendar.duck"

import { defaultAppointment, getAppointment, getAppointments } from '../../crud/api/calendar.crud';

const Calendar = ({ appointments, user, settingLoader, calendarActions }) => {
  const calendarRef = createRef()
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const [calendarType, setCalendarType] = useState('timeGridWeek')
  const [dialog, setDialog] = useState("close")
  let date = new Date()
  const [title, setTitle] = useState(moment(date).format("MMMM yyyy"))
  const [rangeDates, setRangeDates] = useState({
    start: `${date.getFullYear()}-${date.getMonth() +1}-01 00:00`,
    end: `${date.getFullYear()}-${date.getMonth() +2}-01 00:00`,
  })

  const getRangeDates = (currentRange) => {
    settingLoader(true)
    let start = `${moment(currentRange.start).format("YYYY-MM-DD")} 00:00`
    let end = `${moment(currentRange.end).format("YYYY-MM-DD")} 23:59`
    
    if ((start !== rangeDates.start) || (end !== rangeDates.end)) {
      setRangeDates({
        start: start,
        end: end
      })
      getAppointments(calendarActions.setAppointments, user.id, start, end)
    }
  }

  const changeView = type => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(type)
    setCalendarType(type)
    setTitle(calendarApi.currentDataManager.data.viewTitle)

    const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
    getRangeDates(currentRange)
  }

  const openDialog = async (mode, infoDate = null) => {
    switch(mode){
      case "create":
        calendarActions.setAppointment(defaultAppointment)
        setDialog(mode)
        break;
      case "editing":
        settingLoader(true)
        try {
          await getAppointment(calendarActions.setAppointment, infoDate.event._def.publicId)
          setDialog(mode)
        } catch (error) {
          
        }
        break;
      case "close":
        getAppointments(calendarActions.setAppointments, user.id, rangeDates.start, rangeDates.end)
        .then(() => {setDialog(mode); calendarActions.setAppointment(defaultAppointment)})
        break;
      default:
        console.error("Invalid dialog mode")
    }
  }

  return (
    <>
      <Paper style={{ width: "100%" }} className="px-5 py-4 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Button
            id="calendar_today_btn"
            className="mr-3"
            variant="outlined"
            color="secondary"
            onClick={() => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.today()
              setTitle(calendarApi.currentDataManager.data.viewTitle)

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }}>today</Button>
          <IconButton
            id="calendar_prev_btn"
            color="secondary"
            onClick={() => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.prev()
              setTitle(calendarApi.currentDataManager.data.viewTitle)

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            id="calendar_next_btn"
            color="secondary"
            onClick={() => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.next()
              setTitle(calendarApi.currentDataManager.data.viewTitle)

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }}
          >
            <ChevronRight />
          </IconButton>
          <h1 className="ml-3">{title}</h1>
        </div>

        <Button id="create_appointment_btn" variant="contained" color="secondary" onClick={() => openDialog("create")} startIcon={<Add />}>
          New appointment
        </Button>

        <ButtonGroup color="secondary">
          <Button id="list_week_calendar_view_btn" onClick={() => changeView("listWeek")} disabled={calendarType === "listWeek"}>list</Button>
          <Button id="grid_day_calendar_view_btn" onClick={() => changeView("timeGridDay")} disabled={calendarType === "timeGridDay"}>day</Button>
          <Button id="grid_week_calendar_view_btn" onClick={() => changeView("timeGridWeek")} disabled={calendarType === "timeGridWeek"}>week</Button>
          <Button id="grid_month_calendar_view_btn" onClick={() => changeView("dayGridMonth")} disabled={calendarType === "dayGridMonth"}>month</Button>
        </ButtonGroup>
      </Paper>
      
      <FullCalendar
        ref={calendarRef}
        height={isDesktop ? "92%" : "86%"}
        timeZone='local'
        initialView='timeGridWeek'
        allDaySlot={false}
        dayHeaderFormat={calendarType !== "dayGridMonth" ? { weekday: "short", day: "numeric" } : { weekday: "short" }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin
        ]}
        // current are on display none from css, if want to back to show, erase the display: none from .fc-toolbar-title
        headerToolbar={{
          left: "customTodayBtn customPrevBtn customNextBtn title", 
          center: "listWeek,timeGridDay,timeGridWeek,dayGridMonth",
          right: 'customNewAppointment'
        }}
        events={appointments}
        eventClick={info => `${moment(info.event.start).format("YYYY-MM-DD")} 00:00` >= `${moment(date).format("YYYY-MM-DD")} 00:00` && openDialog("editing", info)}  //edit event on "this" date
        //select={info => openDialog("create"), info}  // creates new one from "that" date
        customButtons={{
          customTodayBtn: {
            text: "Today",
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.today()

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }
          },
          customPrevBtn: {
            text: "<",
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.prev()

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }
          },
          customNextBtn: {
            text: ">",
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.next()

              const { currentRange } = calendarRef.current.getApi().currentDataManager.data.dateProfile;
              getRangeDates(currentRange)
            }
          },
          customNewAppointment: {
            text: "NEW APPOINTMENT",
            click: () => openDialog("create")
          }
        }}
      />

      <CalendarDialog mode={dialog} setDialog={openDialog} />
    </>
  )
}

const mapStateToProps = store => ({
  user: store.auth.user,
  appointments: store.calendar.appointments.data,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  calendarActions: bindActionCreators(calendarDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
