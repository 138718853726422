/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, MenuItem, CircularProgress } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik'
import moment from "moment"

import { Search } from '../../utils/Search';
import { searchStudents } from '../../crud/api/students.crud';
import { searchStaffMembers } from '../../crud/api/staff_members.crud';
import { deleteAppointment, saveAppointment, updateAppointment, updateEventAction } from '../../crud/api/calendar.crud';

import * as calendarDUCK from "../../../redux/ducks/calendar.duck"
import { Delete } from '@material-ui/icons';
import { setAlert } from '../../../redux/ducks/alert.duck';

const CalendarDialog = ({ mode, setDialog, appointment, school, user, calendarActions, setAlert }) => {
  const title = mode === 'editing' ? "Edit Appointment" : "New Appointment"
  const open = mode === 'editing' || mode === 'create'
  const [loading, setLoading] = useState(false);
  const [loaders, setLoaders] = useState({
    confirm: false,
    reject: false,
    cancel: false,
  })

  const create = async values => {
    let start = new Date(`${moment(values.date).format("YYYY-MM-DD")} ${values.starts_at}`)
    let end = new Date(`${moment(values.date).format("YYYY-MM-DD")} ${values.ends_at}`)
    
    const data = {
      name: values.name,
      staff_member_id: user.id,
      student_id: values.student && values.student !== null ? values.student.id : null,
      starts_at: start,
      ends_at: end,
      notes: values.notes,
      school_id: school.id,
      created_by: "staff"
      // invited_students_ids: values.invited_students_ids,
    }
    
    try {
      await saveAppointment(calendarActions.updateAppointments, data)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {
    let start = new Date(`${moment(values.date).format("YYYY-MM-DD")} ${values.starts_at}`)
    let end = new Date(`${moment(values.date).format("YYYY-MM-DD")} ${values.ends_at}`)

    const data = {
      name: values.name,
      staff_member_id: user.id,
      student_id: values.student && values.student !== null ? values.student.id : null,
      starts_at: start,
      ends_at: end,
      notes: values.notes,
      school_id: school.id,
      created_by: "staff"
      // invited_students_ids: values.invited_students_ids,
    }
    try {
      await updateAppointment(calendarActions.updateAppointments, data, appointment.id)
      setLoading(false)
      setDialog("close")
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteAppointments = (id) => {
    setAlert({
      title: "Delete appointment",
      message: "Are you sure to permanently delete this appointment?",
      btn_msg: "Delete appointment",
      action: () => deleteAppointment(calendarActions.deleteAppointments, id).then(() => setDialog("close"))
    })
  }

  const updateAction = (action) => {
    setLoaders({...loaders, [action]: true})
    setTimeout(async () => {
      try {
        await updateEventAction(appointment.id, action)
        setLoaders({...loaders, [action]: false})
        setDialog("close")
      } catch (error) {
        
      }
      
    }, 1000);
  }

  const timeOptions = [ 
   {value: "00:00", label: "00:00 am"}, {value: "00:15", label: "00:15 am"}, {value: "00:30", label: "00:30 am"}, {value: "00:45", label: "00:45 am"}, {value: "01:00", label: "01:00 am"}, {value: "01:15", label: "01:15 am"}, {value: "01:30", label: "01:30 am"}, {value: "01:45", label: "01:45 am"}, {value: "02:00", label: "02:00 am"}, {value: "02:15", label: "02:15 am"}, {value: "02:30", label: "02:30 am"}, {value: "02:45", label: "02:45 am"}, {value: "03:00", label: "03:00 am"}, {value: "03:15", label: "03:15 am"}, {value: "03:30", label: "03:30 am"}, {value: "03:45", label: "03:45 am"}, {value: "04:00", label: "04:00 am"}, {value: "04:15", label: "04:15 am"}, {value: "04:30", label: "04:30 am"}, {value: "04:45", label: "04:45 am"}, {value: "05:00", label: "05:00 am"}, {value: "05:15", label: "05:15 am"}, {value: "05:30", label: "05:30 am"}, {value: "05:45", label: "05:45 am"},
   {value: "06:00", label: "06:00 am"}, {value: "06:15", label: "06:15 am"}, {value: "06:30", label: "06:30 am"}, {value: "06:45", label: "06:45 am"}, {value: "07:00", label: "07:00 am"}, {value: "07:15", label: "07:15 am"}, {value: "07:30", label: "07:30 am"}, {value: "07:45", label: "07:45 am"}, {value: "08:00", label: "08:00 am"}, {value: "08:15", label: "08:15 am"}, {value: "08:30", label: "08:30 am"}, {value: "08:45", label: "08:45 am"}, {value: "09:00", label: "09:00 am"}, {value: "09:15", label: "09:15 am"}, {value: "09:30", label: "09:30 am"}, {value: "09:45", label: "09:45 am"}, {value: "10:00", label: "10:00 am"}, {value: "10:15", label: "10:15 am"}, {value: "10:30", label: "10:30 am"}, {value: "10:45", label: "10:45 am"}, {value: "11:00", label: "11:00 am"}, {value: "11:15", label: "11:15 am"}, {value: "11:30", label: "11:30 am"}, {value: "11:45", label: "11:45 am"},
   {value: "12:00", label: "12:00 pm"}, {value: "12:15", label: "12:15 pm"}, {value: "12:30", label: "12:30 pm"}, {value: "12:45", label: "12:45 pm"}, {value: "13:00", label: "01:00 pm"}, {value: "13:15", label: "01:15 pm"}, {value: "13:30", label: "01:30 pm"}, {value: "13:45", label: "01:45 pm"}, {value: "14:00", label: "02:00 pm"}, {value: "14:15", label: "02:15 pm"}, {value: "14:30", label: "02:30 pm"}, {value: "14:45", label: "02:45 pm"}, {value: "15:00", label: "03:00 pm"}, {value: "15:15", label: "03:15 pm"}, {value: "15:30", label: "03:30 pm"}, {value: "15:45", label: "03:45 pm"}, {value: "16:00", label: "04:00 pm"}, {value: "16:15", label: "04:15 pm"}, {value: "16:30", label: "04:30 pm"}, {value: "16:45", label: "04:45 pm"}, {value: "17:00", label: "05:00 pm"}, {value: "17:15", label: "05:15 pm"}, {value: "17:30", label: "05:30 pm"}, {value: "17:45", label: "05:45 pm"},
   {value: "18:00", label: "06:00 pm"}, {value: "18:15", label: "06:15 pm"}, {value: "18:30", label: "06:30 pm"}, {value: "18:45", label: "06:45 pm"}, {value: "19:00", label: "07:00 pm"}, {value: "19:15", label: "07:15 pm"}, {value: "19:30", label: "07:30 pm"}, {value: "19:45", label: "07:45 pm"}, {value: "20:00", label: "08:00 pm"}, {value: "20:15", label: "08:15 pm"}, {value: "20:30", label: "08:30 pm"}, {value: "20:45", label: "08:45 pm"}, {value: "21:00", label: "09:00 pm"}, {value: "21:15", label: "09:15 pm"}, {value: "21:30", label: "09:30 pm"}, {value: "21:45", label: "09:45 pm"}, {value: "22:00", label: "10:00 pm"}, {value: "22:15", label: "10:15 pm"}, {value: "22:30", label: "10:30 pm"}, {value: "22:45", label: "10:45 pm"}, {value: "23:00", label: "11:00 pm"}, {value: "23:15", label: "11:15 pm"}, {value: "23:30", label: "11:30 pm"}, {value: "23:45", label: "11:45 pm"},
  ]

  return (
    <Dialog open={open} onClose={() => setDialog("close")} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...appointment,
          date: appointment.starts_at,
          starts_at:appointment.starts_at === null ? "" : moment(appointment.starts_at).format("HH:mm"),
          ends_at:appointment.ends_at === null ? "" : moment(appointment.ends_at).format("HH:mm"),
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Field required'
          if (values.starts_at > values.ends_at) errors.starts_at = 'Start time must be less than end time'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            appointment.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}
      >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  id="calendar_school_input_dialog"
                  variant="outlined"
                  label="School"
                  name="school"
                  value={school.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="calendar_name_input_dialog"
                  variant="outlined"
                  label="Title"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Search
                  setterFunction={setFieldValue}
                  field="student"
                  label="Student"
                  value={values.student}
                  searchFunction={searchStudents}
                  slug={school.slug}
                />
              </Grid>
              {user.is_admin && <Grid item xs={12} sm={6}>
                <Search
                  setterFunction={setFieldValue}
                  field="staff_member"
                  label="Staff member"
                  value={values.staff_member}
                  searchFunction={searchStaffMembers}
                  slug={school.slug}
                />
              </Grid>}
              <Grid item xs={12} sm={5}>
                <TextField
                  select
                  fullWidth
                  id="calendar_tag_input_dialog"
                  variant="outlined"
                  label="Tag"
                  name="tag"
                  value={values.tag}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="self" id="tag_self">Self</MenuItem>
                  <MenuItem value="Sports" id="tag_Sports">Sports</MenuItem>
                  <MenuItem value="School" id="tag_School">School</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={8} sm={3}>
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disableToolbar
                  variant="inline"
                  id="calendar_date_input_dialog"
                  inputVariant="outlined"
                  label="Date"
                  name='date'
                  format="MMMM d, yyyy"
                  value={values.date}
                  onChange={value => setFieldValue("date", value)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <TextField
                  select
                  fullWidth
                  disabled={Boolean(values.date === null)}
                  label="Start time"
                  id="calendar_time_start_input_dialog"
                  variant="outlined"
                  name="starts_at"
                  value={values.starts_at}
                  onChange={e => {
                    handleChange(e)
                    let i = timeOptions.findIndex(x => x.value === e.target.value)

                    if (i < 92 ) setFieldValue("ends_at", timeOptions[i +4].value)

                    else setFieldValue("ends_at", "23:45")

                  }}
                  onBlur={handleBlur}
                >
                {timeOptions.map((time, i) => (
                  <MenuItem key={time.value} value={time.value} id={`start_time_${i}`}>{time.label}</MenuItem>
                ))}
                </TextField>
              </Grid>
              <Grid item xs={2} sm={2}>
                <TextField
                  select
                  fullWidth
                  disabled={Boolean(values.date === null)}
                  label="End time"
                  id="calendar_time_end_input_dialog"
                  variant="outlined"
                  name="ends_at"
                  value={values.ends_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                {timeOptions.map((time, i) => (
                  <MenuItem key={time.value} value={time.value} disabled={time.value <= values.starts_at} id={`ends_time_${i}`}>{time.label}</MenuItem>
                ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax={4}
                  id="calendar_location_input_dialog"
                  variant="outlined"
                  name="location"
                  label="Location"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax={4}
                  id="calendar_note_input_dialog"
                  variant="outlined"
                  name="notes"
                  label="Notes"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {values.available_actions && values.available_actions.length > 0 && <Grid item xs={12}>
                <h6>Available actions</h6>
                {values.available_actions.includes("confirm") && <a id="calendar_confirm_action_btn_dialog" type="button" className="btn btn-success btn-lg btn-block d-flex justify-content-between d-flex align-items-center" onClick={() => updateAction("confirm")}>Confirm {loaders.confirm && <CircularProgress color="inherit" size={18} className="ml-5" />}</a>}
                {values.available_actions.includes("decline") && <a id="calendar_decline_action_btn_dialog" type="button" className="btn btn-warning btn-lg btn-block d-flex justify-content-between d-flex align-items-center" onClick={() => updateAction("reject")}>Decline {loaders.decline && <CircularProgress color="inherit" size={18} className="ml-5" />}</a>}
                {values.available_actions.includes("cancel") && <a id="calendar_cancel_action_btn_dialog" type="button" className="btn btn-danger btn-lg btn-block d-flex justify-content-between d-flex align-items-center" onClick={() => updateAction("cancel")}>Cancel {loaders.cancel && <CircularProgress color="inherit" size={18} className="ml-5" />}</a>}
              </Grid>}
            </Grid>
          </DialogContent>
          <DialogActions className={appointment.id && "justify-content-between"}>
            {appointment.id && <button id="delete_calendar_btn_dialog" type="reset" className="btn btn-danger" onClick={() => deleteAppointments(appointment.id)}>
              <Delete /> Delete appointment
            </button>}
            <div className="d-flex">
              <Button id="cancel_calendar_btn_dialog" onClick={() => setDialog("close")} color="secondary">
                Cancel
              </Button>
              <button
                type="submit"
                id="submit_calendar_btn_dialog"
                disabled={isSubmitting || !values.name || values.date === null || !values.starts_at || !values.ends_at || (!school || school === null)}
                className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                {mode === "editing" ? "Submit" : "Create"}
                {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
              </button>
            </div>
          </DialogActions>
        </form>
      )}
      </Formik>
      
    </Dialog>
  )
}

const mapStateToProps = store => {
  return ({
    school: store.school.selected_school,
    user: store.auth.user,
    appointment: store.calendar.appointment,
  })
}

const mapDispatchToProps = dispatch => ({
  calendarActions: bindActionCreators(calendarDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDialog)
