import React, { useEffect, useState } from 'react'
import { makeStyles, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery } from '@material-ui/core'
import { FileDropInput } from './FileDropInput'
import { Autocomplete } from '@material-ui/lab'
import { Formik } from 'formik'
import { SCHOOL_URL } from '../crud/helpers/routes'
import { getMultipartParams, handleResponse } from '../crud/helpers/validate'

const useStyles = makeStyles(theme => ({
  paper: {
    width: ({ isDesktop }) => !isDesktop ? '70%' : '50%',
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    },
    maxWidth: 500,
  },
  progress: {
    marginLeft: 10
  }
}));

export const BulkModal = ({ open, setOpen, bulk, setBulk, school, loading, setLoading, bulkRequest = () => {} }) => {
  const [teams, setTeams] = useState([]);
  const isDesktop = useMediaQuery("(min-width: 600px)")
  const classes = useStyles({ isDesktop });
  const getTeams = (setterFunction, slug) => {
      return fetch(`${SCHOOL_URL}/${slug}/teams?per_page=100`, getMultipartParams('GET'))
          .then(res => handleResponse(res))
          .then(json => setterFunction(json.items))
          .catch(e => {
              console.error(e.errors)
              return e
          })
  }

  useEffect(() => {
      if (school && school !== null && school.slug && school.slug !== null) getTeams(setTeams, school.slug)
  }, [school]);

  return (
    <Dialog
      PaperProps={{
        className: classes.paper
      }}
      open={open}
      id="bulk_dialog"
      onClose={() => setOpen(false)}
    >
      <Formik
        initialValues={{
          team_id: null,
        }}
        validate={values => {
          const errors = {}
          if (!values.team_id) errors.team_id = 'Required field';
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          bulkRequest(values);
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <>
          <DialogTitle className="pb-1">Upload CSV File</DialogTitle>
          <DialogContent className="py-0">
            <div className="mb-4">
            <a href="https://s3.amazonaws.com/storage.itsthezone/assets/the_zone_csv_template.csv" target="_blank" rel="noopener noreferrer" className="mb-5">
                Download CSV Template
              </a>
            </div>
            
            <Autocomplete
                selectedAutocompleteValues={values.team_id}
                id="bulk_team_id"
                className="mb-5"
                options={teams}
                defaultValue={[]}setBulk
                getOptionLabel={(option) => option.name}
                onChange={(event, val) => {
                    const teamId = val?.id;
                    setFieldValue('team_id', teamId);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Teams"
                    />
                )}
            />
          <FileDropInput setterFunction={setBulk} value={bulk} type=".csv" message="Add CSV file" />
          </DialogContent>
          <DialogActions>
            <Button id="cancel_bulk_btn_dialog" type="reset" onClick={() => setOpen(false)} color="secondary">
            Cancel
            </Button>
            <button
              type="submit"
              id="submit_bulk_btn_dialog"
              disabled={!bulk || !school?.slug || !values.team_id}
              onClick={handleSubmit}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                Submit
              {loading && <CircularProgress color="inherit" size={18} className={classes.progress} />}
            </button>
          </DialogActions>
        </>
      )}
        
      </Formik>
    </Dialog>
  )
}
