import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import notice from "./notice.js"

export const Search = ({ setterFunction, field, value, label, error, searchFunction, slug, disabled=false }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("")
  const loading = open && options.length === 0;

  useEffect(() => {
    if (slug) {
      if (open) searchFunction(setOptions, slug, search)
    } else notice("No school was selected")
  }, [open, search, searchFunction, slug])

  useEffect(() => {
    if (!open) setOptions([])

  }, [open])

  return (
    <Autocomplete
      disabled={disabled}
      id={field + "_search_input"}
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      value={value}
      inputValue={search}
      onChange={(e, newValue) => setterFunction(field, newValue)}
      onInputChange={(e, newInputValue) => setSearch(newInputValue)}
      getOptionSelected={(option, value) => (option.name === value.name) || (option.first_name === value.first_name) || (options.user.first_name === value.user.first_name)}
      getOptionLabel={(option) => {
        let fullName
        option.user && option.user.first_name
        ? fullName = `${option.user.first_name} ${option.user.last_name}`
        : fullName = `${option.first_name} ${option.last_name}`

        return option ? option.name || fullName : ""
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          //error={Boolean(error)}
          //helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
