export default {
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "Map/Position.svg",
        page: "/dashboard"
      },
      /*{
        title: "Student Goals",
        root: true,
        icon: "Shopping/Chart-bar2.svg",
        page: "/goals"
      },*/
      {
        title: "University Hub",
        root: true,
        icon: "Design/Layers.svg",
        page: "/announcements"
      },
      {
        title: "Push Notifications",
        root: true,
        icon: "Communication/Archive.svg",
        page: "/notifications"
      },
      {
        title: "Polls",
        root: true,
        icon: "Communication/Mail-opened.svg",
        page: "/polls",
        admin: true,
      },
      // {
      //   title: "Calendar",
      //   root: true,
      //   icon: "Layout/Layout-top-panel-6.svg",
      //   page: "/calendar"
      // },
      {
        title: "Users",
        root: true,
        icon: "General/User.svg",
        page: "/users",
        submenu: [
          {
            title: "Students",
            page: "/users/student"
          },
          {
            title: "Staff",
            page: "/users/staff"
          },
          // {
          //   title: "Admin",
          //   page: "/users/admin"
          // },
          /*{
            title: "Alumni",
            page: "/users/alumni"
          },*/
        ]
      },
      {
        title: "Teams",
        root: true,
        icon: "Communication/Group.svg",
        page: "/teams"
      },
      {
        title: "Schools",
        root: true,
        icon: "Home/Building.svg",
        page: "/schools"
      },
      {
        title: "Library",
        root: true,
        icon: "Home/Book-open.svg",
        page: "/contents",
        admin: true,
      },
      // {
      //   title: "Daily Picks",
      //   root: true,
      //   icon: "Shopping/Loader.svg",
      //   page: "/daily_picks",
      //   submenu: [
      //     {
      //       title: "Mind",
      //       page: "/daily_picks/mind"
      //     },
      //     {
      //       title: "Body",
      //       page: "/daily_picks/body"
      //     },
      //     {
      //       title: "Life",
      //       page: "/daily_picks/life"
      //     },
      //   ]
      // },
      {
        title: "Configuration",
        root: true,
        icon: "Design/Component.svg",
        page: "/config",
        submenu: [
          // {
          //   title: "Staff types",
          //   page: "/config/staff_types"
          // },
          {
            title: "Moods",
            page: "/config/emotions"
          },
          // {
          //   title: "Sports",
          //   page: "/config/sports"
          // },
          /*{
            title: "Conferences",
            page: "/config/conference"
          },*/
          // {
          //   title: "Goals",
          //   page: "/config/goal_types"
          // },
          /*{
            title: "Content Authors",
            page: "/config/content_authors"
          },*/
          {
            title: "Focus Areas",
            page: "/config/focus_areas",
            admin: true,
          },
        ],
        staff_member_admin: true,
      },
    ]
  }
};